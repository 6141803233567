import {Component, Input} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Options} from 'highcharts';
import {map, Subject, takeUntil} from 'rxjs';
import {PrecipitationHourlyChart} from '../helpers/PrecipitationHourlyChart';
import {SensorData, SensorService} from '../sensor.service';

@Component({
  selector: 'met-precipitation-hourly-chart',
  templateUrl: './precipitation-hourly-chart.component.html',
  styleUrls: ['./precipitation-hourly-chart.component.scss'],
})
export class PrecipitationHourlyChartComponent {
  constructor(private sensorService: SensorService) {}

  @Input()
  appId!: string;

  @Input()
  date!: Date;

  @Input()
  backDays!: number;

  private unsubscribe$ = new Subject<void>();

  chartDataPrecipitationHourly: any = [];

  precipitationHourlyChart: Chart | undefined;

  now = new Date();

  convertDateTime(date: string) {
    return Date.parse(date);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getDataFromKachelmannApi();
  }

  private getDataFromKachelmannApi() {
    this.chartDataPrecipitationHourly = [];

    this.precipitationHourlyChart = new Chart(PrecipitationHourlyChart() as Options);
    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'PRECIPITATION_HOURLY')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataPrecipitationHourly.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.precipitationHourlyChart?.addSeries(
            {
              type: 'column',
              name: 'Niederschlag',
              color: 'blue',
              data: this.chartDataPrecipitationHourly,
            },
            true,
            false
          );
        })
      )
      .subscribe();
  }
}
