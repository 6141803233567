export enum StatusType {
    DISTURBANCE = "DISTURBANCE",
    OK = "OK"
};

export interface InformationStatus {
    message: string;
    informationStatusType: StatusType;
    isActive: boolean;
}
