<met-page-layout>
  <div class="operator-view">
    <div class="title">
      <h3 routerLink="/operators" style="cursor: pointer; text-decoration: underline">Betreiberübersicht</h3>
      <mat-icon class="mat-icon-header">keyboard_arrow_right</mat-icon>
      <h3 *ngIf="operator.lastName; else vhOperatorIds">{{ operator.lastName }}</h3>
      <ng-template #vhOperatorIds>
        <h3>{{ operator.vhOperatorId }}</h3>
      </ng-template>
    </div>
    <div class="edit-Operator-view">
      <div>
        <div>
          <met-edit-operator-field fieldName="Id" [operatorValue]="operator.vhOperatorId"
                                   (valueChange)="changeValue('id', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="Vorname" [operatorValue]="operator.firstName"
                                   (valueChange)="changeValue('firstName', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="Nachname" [operatorValue]="operator.lastName"
                                   (valueChange)="changeValue('lastName', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="Straße" [operatorValue]="operator.street"
                                   (valueChange)="changeValue('street', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="PLZ" [operatorValue]="operator.postCode"
                                   (valueChange)="changeValue('postCode', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="Ort" [operatorValue]="operator.city"
                                   (valueChange)="changeValue('city', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="Telefon" [operatorValue]="operator.phoneNumber"
                                   (valueChange)="changeValue('phoneNumber', $event)"></met-edit-operator-field>
        </div>
        <div>
          <met-edit-operator-field fieldName="E-Mail" [operatorValue]="operator.email"
                                   (valueChange)="changeValue('email', $event)"></met-edit-operator-field>
        </div>
        <div class="headline">
          Stationen des Betreibers
        </div>
        <div class="stations" *ngFor="let station of operator.stationMap | keyvalue">
          <div>
            <met-operator-station-view [stationId]="station.key" [stationName]="station.value"
                                       (delete)="deleteStation(station.key)"
            ></met-operator-station-view>
          </div>
        </div>
        <div class="add-icon">
          <mat-icon style="transform: scale(1.5); cursor: pointer" (click)="openAddStation()">add</mat-icon>
        </div>
        <div *ngIf="status" class="status-message">
          Änderungen wurden gespeichert!
        </div>
        <div class="buttons">
          <div>
            <met-button buttonText="Abbrechen" routerLink="/operators"></met-button>
          </div>
          <div>
            <met-button *ngIf="deletable" buttonText="Löschen"
                        (click)="openDialog()"></met-button>
          </div>
          <div>
            <met-button buttonText="Speichern" (click)="save()"></met-button>
          </div>
        </div>
      </div>
    </div>

  </div>

</met-page-layout>
