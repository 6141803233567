<met-page-layout>
  <div>
    <div class="title">
      <h3 routerLink="/stations" style="text-decoration: underline; cursor: pointer">Stationsübersicht</h3>
      <mat-icon class="mat-icon-header">keyboard_arrow_right</mat-icon>
      <h3 style="text-decoration: underline; cursor: pointer" [routerLink]="['/station-view', stationId]">
        {{ stationId }}
      </h3>
      <mat-icon class="mat-icon-header">keyboard_arrow_right</mat-icon>
      <h3>Diagramme</h3>
    </div>
  </div>
  <div *ngIf="mappedStations$ | async as mappedStations" else #loadingIndicator>
    <met-point-diagram
      [mappedStations]="mappedStations"
      [selectedTimeInterval]="selectedTimeInterval"
      (switchTimeInterval)="mapSensorDataToObject($event)"
    ></met-point-diagram>
    <div class="row" style="display: flex">
      <met-button
        [isRaised]="selectedBackDays === 1"
        style="margin-right: 12px"
        [buttonText]="'Tagesansicht'"
        (click)="backDaysChange(1)"
      ></met-button>
      <met-button
        style="margin-right: 12px"
        [isRaised]="selectedBackDays === 30"
        [buttonText]="'Monatsansicht'"
        (click)="backDaysChange(30)"
      ></met-button>
      <met-button
        style="margin-right: 12px"
        [isRaised]="selectedBackDays === 365"
        [buttonText]="'Jahresansicht'"
        (click)="backDaysChange(365)"
      ></met-button>
    </div>
    <div class="row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Relative Luftfeuchtigkeit (%)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <met-humidity-chart
            id="humidity-chart"
            [appId]="appId"
            [backDays]="selectedBackDays"
            [stationType]="stationType"
          ></met-humidity-chart>
        </ng-template>
      </mat-expansion-panel>
    </div>
    <div class="row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Temperaturdiagramm (°C)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <met-temperature-chart
            id="temperature-chart"
            [appId]="appId"
            [backDays]="selectedBackDays"
          ></met-temperature-chart>
        </ng-template>
      </mat-expansion-panel>
    </div>
    <div class="row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Winddiagramm
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <met-wind-chart id="wind-chart" [appId]="appId" [backDays]="selectedBackDays"></met-wind-chart>
        </ng-template>
      </mat-expansion-panel>
    </div>
    <div class="row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Niederschlagsdiagramm (mm)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <met-precipitation-hourly-chart
            id="precipitation-hourly-chart"
            [appId]="appId"
            [backDays]="selectedBackDays"
          ></met-precipitation-hourly-chart>
        </ng-template>
      </mat-expansion-panel>
    </div>
    <div class="row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Niederschlagsdiagramm (total)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <met-precipitation-chart
            id="precipitation-chart"
            [appId]="appId"
            [backDays]="selectedBackDays"
          ></met-precipitation-chart>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </div>
  <ng-template #loadingIndicator>Test</ng-template>
</met-page-layout>
