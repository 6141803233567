import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ImageService, ImageUrl } from "./image.service";
import { Observable, of, take } from "rxjs";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ImageFullscreenViewerComponent } from '../image-fullscreen-viewer/image-fullscreen-viewer.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserRoleProviderService } from '../auth/user-role-provider-service';
import { Role } from '../model/Role';

@Component({
  selector: 'met-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ImagesComponent implements OnInit {
  @Input()
  stationId: string | null = ""

  @Input()
  requiredFileTypes: string[] = [
    'image/jpg',
    'image/png',
    'image/gif',
  ]

  fileNames: File[] = [];
  imageUrls$: Observable<ImageUrl[]> = of([]);
  errorMessage: string | undefined;
  readonly role = Role;

  private dialogRef: MatDialogRef<ConfirmationDialogComponent> | undefined;

  constructor(private imageService: ImageService, private dialog: MatDialog, private userRoleProviderService: UserRoleProviderService) {
  }

  ngOnInit(): void {
    if (this.stationId) {
      this.imageUrls$ = this.imageService.getStationImageUrls(this.stationId);
    }
  }

  hasRequiredRole(requiredRole: Role): boolean {
    return this.userRoleProviderService.hasRequiredRole(requiredRole);
  }

  selectFiles(event: Event): void {
    const target = event.target as HTMLInputElement
    this.fileNames = Array.from(target.files as ArrayLike<File>);

    this.uploadFiles();
  }

  uploadFiles(): void {
    if (this.fileNames) {
      for (let i = 0; i < this.fileNames.length; i++) {
        this.upload(i, this.fileNames[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    if (file && this.stationId) {
      this.imageService.uploadFile(this.stationId, file)
        .subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              let value = Math.round(100 * event.loaded / event.total);
              console.log("Progress " + file.name + ": " + value)
            } else if (event instanceof HttpResponse) {
              console.log('Uploaded the file successfully: ' + file.name);
            }
          },
          complete: () => {
            this.ngOnInit();
          },
          error: () => this.errorMessage = "Es ist ein Fehler aufgetreten, das Bild konnte nicht gespeichert werden."
        });
    }
  }

  displayImageFullscreen(imgUrl: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '80vh';
    dialogConfig.width = '80vw';
    dialogConfig.autoFocus = false;
    dialogConfig.data = imgUrl;

    this.dialog.open(ImageFullscreenViewerComponent, dialogConfig);
  }

  openConfirmationDialogForDeletion(imageUrl: ImageUrl) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = "Bild wirklich löschen?";

    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteImage(imageUrl);
      }
      this.dialogRef = undefined;
    });
  }

  private deleteImage(imageUrl: ImageUrl): void {
    const fileName = this.parseFileNameFromUrl(imageUrl.thumbnailUrl);

    if (!fileName) {
      this.errorMessage = "Es ist ein Fehler beim Auslesen des Dateinamens aufgetreten, das Bild konnte nicht gelöscht werden."
      return;
    }

    this.imageService.deleteImage(this.stationId, fileName)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.errorMessage = undefined;
          this.ngOnInit();
        },
        error: () => this.errorMessage = "Es ist ein Fehler aufgetreten, das Bild konnte nicht gelöscht werden."
      });
  }

  private parseFileNameFromUrl(url: string): string | null {
    const regExp = new RegExp('(thumbnail_)(.*?)(.jpg|.png|.gif)', 'g');
    const foundSubstrings = url.match(regExp);

    if (foundSubstrings && foundSubstrings.length === 1) {
      return foundSubstrings[0];
    }

    return null;
  }
}
