<div class="container">
  <div class="button-wrapper">
    <a [attr.href]="imageURL">
      <button mat-icon-button>
        <mat-icon>download</mat-icon>
      </button>
    </a>
    <button mat-icon-button (click)="closeViewer()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <img id="fullScreenImage" [src]="imageURL"/>
</div>