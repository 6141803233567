import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'met-operator-add-station',
  templateUrl: './operator-add-station.component.html',
  styleUrls: ['./operator-add-station.component.scss']
})
export class OperatorAddStationComponent implements OnInit {
  private stationName: any;

  constructor(public dialogRef: MatDialogRef<OperatorAddStationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  private stations: Map<string, string> = new Map
  filteredOptions: Observable<string[]> | undefined;

  control = new FormControl('');

  ngOnInit(): void {
    this.stations = this.data.stations
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  setStation($event: MatAutocompleteSelectedEvent) {
    this.stationName = $event.option.value
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return Array.from(this.stations.values()).filter(
      value => value.toLowerCase().includes(filterValue)
    )
  }

  onSave(): void {
    console.log(this.stationName)
    this.dialogRef.close({data: this.stationName})
  }
}
