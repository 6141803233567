export const TemperatureChart = () => {
  return {
    chart: {},
    title: {
      text: 'Temperatur (°C)',
      align: 'left',
      margin: 50,
    },
    xAxis: {
      gridLineWidth: 1,
      type: 'datetime',
      dateTimeLabelFormats: {
        // don't display the dummy year
        minute: '%H:%M',
        hour: '%H:%M',
        month: '%e. %b',
        year: '%b',
      },
      title: {
        // text: 'Date'
      },
    },
    yAxis: [
      {
        title: {
          text: 'Temperatur',
        },
        gridLineWidth: 1,

      },
    ],
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b, %H:%M} <br> {point.y:.2f} °C',
    },
    rangeSelector: {
      enabled: false,
    },

    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 1,
        },
      },
    },

    // Define the data points. All series have a dummy year of 1970/71 in order
    // to be compared on the same x axis. Note that in JavaScript, months start
    // at 0 for January, 1 for February etc.
    series: [],
  };
};
