import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StatusComment } from './StatusComment';
import { Sensor } from '../model/Sensor';
import { Observable, take } from 'rxjs';
import { Station } from '../model/Station';
import { RemarkType } from '../model/RemarkType';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogCommentComponent } from "../dialog-comment/dialog-comment.component";
import { Remark } from '../model/Remark';
import {DialogSensorComponent} from "../dialog-sensor/dialog-sensor.component";

@Injectable({
  providedIn: 'root'
})
export class StationViewService {

  baseUrl = '/sensor/'

  constructor(private http: HttpClient, public dialog: MatDialog) {
  }

  setSensorStatus(stationId: string, sensor: Sensor, statusComment: StatusComment) {
    if (statusComment != StatusComment.NONE) {
      sensor.sensorStatus.startDate = Date.now().toString()
    } else {
      sensor.sensorStatus.startDate = ""
    }
    let index = Object.values(StatusComment).indexOf(statusComment)
    let statusCommentType = Object.keys(StatusComment)[index]
    let httpParams = new HttpParams()
      .append("stationId", stationId)
      .append("sensorType", sensor.sensorType)
      .append("statusComment", statusCommentType);
    return this.http.post(environment.API_URL + this.baseUrl + 'statusComment', httpParams, { observe: 'response' })
  }

  getFilteredSensorData(stationId: string, interval: number) {
    return this.http.get<Sensor[]>(environment.API_URL + this.baseUrl + 'sensorData?stationId=' + stationId + '&interval=' + interval)
  }


  setRemark(remarkType: RemarkType, stationId: string, content: string, sensorType = '', remarkDbId?: number): Observable<any> {
    let remark;
    if (sensorType) {
      remark = {
        id: remarkDbId,
        type: remarkType,
        stationId: stationId,
        text: content,
        sensorType: sensorType
      }
    } else {
      remark = {
        id: remarkDbId,
        type: remarkType,
        stationId: stationId,
        text: content
      }
    }
    return this.http.post(environment.API_URL + '/remark/', remark, { observe: 'response' });
  }

  setRemarkArchived(remarkDbId: number): Observable<any> {
    return this.http.patch(environment.API_URL + '/remark/' + remarkDbId, { observe: 'response' });
  }

  archiveRemarkNoteOnStation(station: Station) {
    if (station.note?.id) {
      this.setRemarkArchived(station.note.id)
        .pipe(take(1))
        .subscribe({
          next: () => {
            if (station.notes){
              const index = station.notes.findIndex(x => x.id === station.note!.id);
              if (index > -1){
                station.notes[index].isArchived = true;
              } else {
                station.note!.isArchived = true;
                station.notes.push(station.note!);
              }
              const filtered = station.notes.filter(note => note.isArchived === false)
              station.note = filtered.length > 0 ? filtered.reduce((a, b) => a.creationDate > b.creationDate ? a : b) : undefined
            } else {
              station.note!.isArchived = true;
              station.notes = [station.note!];
              station.note = undefined;
            }
          },
          error: (e) => console.error(e)
        });
    }
  }


  archiveSpecificNoteOnStation(station: Station, note: Remark) {
    if (note?.id) {
      this.setRemarkArchived(note.id)
        .pipe(take(1))
        .subscribe({
          next: () => {
            if (station.notes) {
              const index = station.notes.findIndex(x => x.id === note!.id);
              if (index > -1) {
                station.notes[index].isArchived = true;
              } else {
                note!.isArchived = true;
                station.notes.push(note!);
              }
              const filtered = station.notes.filter(note => note.isArchived === false)
              station.note = filtered.length > 0 ? filtered.reduce((a, b) => a.creationDate > b.creationDate ? a : b) : undefined
            } else {
              note!.isArchived = true;
              station.notes = [note!];
              station.note = undefined;
            }
            station.notes = station.notes.sort((a, b) => a.isArchived && !b.isArchived ? 1 : !a.isArchived && b.isArchived ? -1 : (a.creationDate <= b.creationDate ? 1 : -1))
          },
          error: (e) => console.error(e)
        });
    }
  }

  archiveRemarkCommentOnStation(station: Station) {
    if (station.comment?.id) {
      this.setRemarkArchived(station.comment.id)
        .pipe(take(1))
        .subscribe({
          error: (e) => console.error(e),
          complete: () => station.comment = undefined
        });
    }
  }

  archiveRemarkNoteOnSensor(sensor: Sensor) {
    if (sensor.note?.id) {
      this.setRemarkArchived(sensor.note.id)
        // .pipe(take(1))
        .subscribe({
          error: (e) => console.error(e),
          complete: () => sensor.note = undefined
        });
    }
  }

  archiveRemarkCommentOnSensor(sensor: Sensor) {
    if (sensor.comment?.id) {
      this.setRemarkArchived(sensor.comment.id)
        .pipe(take(1))
        .subscribe({
          error: (e) => console.error(e),
          complete: () => sensor.comment = undefined
        });
    }
  }

  openDialogNoteOnStation(station: Station):
    void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '16em',
      data: station.note?.text,
    });
    this.dialogRefStation(dialogRef, station, RemarkType.NOTE, station.note?.id);
  }

  createNoteOnStation(station: Station):
    void {
    const dialogRef = this.dialog.open(DialogSensorComponent, {
      width: '16em',
      data: station.note?.text,
    });
    this.dialogRefStation(dialogRef, station, RemarkType.NOTE, station.note?.id);
  }

  openSpecificNoteOnStation(station: Station, note: Remark):
    void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '16em',
      data: note.text,
    });
    this.dialogRefStation(dialogRef, station, RemarkType.NOTE, note.id);

  }

  openDialogCommentOnStation(station: Station):
    void {
    const dialogRef = this.dialog.open(DialogCommentComponent, {
      width: '16em',
      data: station.comment?.text,
    });

    this.dialogRefStation(dialogRef, station, RemarkType.COMMENT);
  }

  private dialogRefStation(dialogRef: MatDialogRef<DialogComponent, any>, station: Station, remarkType: RemarkType, remarkDbId?: number) {
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res !== undefined) {
          this.setRemark(remarkType, station.stationId, res, '', remarkDbId)
            .pipe(take(1))
            .subscribe({
              next: (response) => {
                if (remarkType === RemarkType.NOTE) {
                  station.note = response.body;
                  if (!station.notes || station.notes.length === 0) {
                    station.notes = [response.body];
                  } else {
                    const index = station.notes.findIndex(x => x.id === response.body.id);
                    if (index > -1) {
                      station.notes[index] = response.body;
                    }
                  }
                } else station.comment = response.body;
              },
              error: (err) => {
                console.error(err);
              }
            });
        }
      });
  }

  openDialogNoteOnSensor(sensor: Sensor, stationId: string):
    void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '16em',
      data: sensor.note?.text,
    });

    this.dialogRef(dialogRef, sensor, RemarkType.NOTE, stationId);
  }

  createNoteOnSensor(sensor: Sensor, stationId: string):
    void {
    const dialogRef = this.dialog.open(DialogSensorComponent, {
      width: '16em',
      data: sensor.note?.text,
    });

    this.dialogRef(dialogRef, sensor, RemarkType.NOTE, stationId);
  }

  openDialogCommentOnSensor(sensor: Sensor, stationId: string):
    void {
    const dialogRef = this.dialog.open(DialogCommentComponent, {
      width: '16em',
      data: sensor.comment?.text,
    });

    this.dialogRef(dialogRef, sensor, RemarkType.COMMENT, stationId);
  }

  private dialogRef(dialogRef: MatDialogRef<DialogComponent, any>, sensor: Sensor, remarkType: RemarkType, stationId: string) {
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result !== undefined) {
          this
            .setRemark(remarkType, stationId, result, sensor.sensorType)
            .pipe(take(1))
            .subscribe((item) => {
              if (item.status === 200) {
                if (remarkType === RemarkType.NOTE) sensor.note = item.body;
                else sensor.comment = item.body;
              } else {
                console.error(item)
              }
            });
        }
      });
  }

  editStation(stationId: string, regionalOfficeName: string, contactPerson: string, gateWayPin: string): Observable<Station> {
    let httpParams = new HttpParams()
      .append("stationId", stationId)
      .append("regionalOffice", regionalOfficeName)
      .append("contactPerson", contactPerson)
      .append("gateWayPin", gateWayPin);
    return this.http.patch<Station>(environment.API_URL + '/station/', httpParams);
  }

}
