import {AfterContentInit, ChangeDetectorRef, Component} from '@angular/core';
import {RegionalOfficeImportComponent} from '../regional-office-import/regional-office-import.component';
import {StationsService} from '../stations/stations.service';
import {MatDialog} from '@angular/material/dialog';
import {StationDashboardRepresentation} from './StationDashboardRepresentation';
import {StationFilterRepresentation} from '../stations/StationFilterRepresentation';
import {StatusComment} from '../station-view/StatusComment';
import {Role} from '../model/Role';
import {UserRoleProviderService} from '../auth/user-role-provider-service';
import {select, Store} from '@ngrx/store';
import {
  selectBatteryState,
  selectCurrentPageNumber,
  selectCurrentPageSize,
  selectElementToScroll,
  selectEndDate,
  selectNoOperator,
  selectRegionalOffices,
  selectSearchString,
  selectStartDate,
  selectStatusComments,
  selectTransmissionSignals
} from '../filterState/filter-state.selectors';
import {
  saveCurrentPageNo,
  saveCurrentPageSize,
  saveElementToScroll,
  saveSearchString
} from '../filterState/filter-state.actions';

@Component({
  selector: 'met-station-dashboard',
  templateUrl: './station-dashboard.component.html',
  styleUrls: ['./station-dashboard.component.scss']
})
export class StationDashboardComponent implements AfterContentInit {

  stationDashboard: StationDashboardRepresentation =
    {
      stationRepresentations: [],
      stationStatisticsRepresentation: {
        installedStations: 0,
        errorFreeStations: 0,
        inactiveStations: 0,
        faultyStations: 0,
        dailyErrorRate: 0,
        averageServiceTimeOfStation: 0
      }
    };
  isLoading: boolean = false;
  interval: number = 2;
  pageNo: number = 0;
  storedPageNo = this.store.pipe(select(selectCurrentPageNumber));
  currentPageSize = 150;
  storedCurrentPageSize = this.store.pipe(select(selectCurrentPageSize));
  pageSizes: number[] = [50, 100, 150, 200];
  searchString: string = "";
  storedSearchString = this.store.pipe(select(selectSearchString))
  regionalOffices = this.store.pipe(select(selectRegionalOffices));
  statusComments = this.store.pipe(select(selectStatusComments));
  statusCommentStartDate = this.store.pipe(select(selectStartDate));
  statusCommentEndDate = this.store.pipe(select(selectEndDate));
  transmissionSignals = this.store.pipe(select(selectTransmissionSignals));
  readonly role = Role;
  filterForBatteryStatus = this.store.pipe(select(selectBatteryState));
  filterForNoOperator = this.store.pipe(select(selectNoOperator));
  searchHelp: string = "Die Suche wird auf die Felder ID, Name, Ansprechpartner und SensorId angewendet!";
  elementToScroll = this.store.pipe(select(selectElementToScroll))
  dashboard = document.getElementById("stations");


  constructor(private stationsService: StationsService, public dialog: MatDialog,
              private changeDetecterRef: ChangeDetectorRef, private userProviderService: UserRoleProviderService, private store: Store) {
  }

  ngAfterContentInit(): void {
    this.updatePage();
  }

  setIntervalAndUpdate(interval: number) {
    this.interval = interval;
    this.updatePage();
  }

  updatePage() {
    this.isLoading = true;
    let regOff: string[] = [];
    let statCom: string[] = [];
    let transSig: string[] = [];
    let filterBattery: string[] = [];
    let filterOperator: string[] = [];
    let startDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;
    this.storedSearchString.subscribe(searchString => this.searchString = searchString)
    this.regionalOffices.subscribe(strings => regOff = strings)
    this.statusComments.subscribe(strings => {
      statCom = strings.map(statusComment => StatusComment.getKeyByValue(statusComment))
    })
    this.transmissionSignals.subscribe(transmissionSignal => transSig = this.mapTransmissionSignals(transmissionSignal))
    this.filterForBatteryStatus.subscribe(batteryState => filterBattery = batteryState)
    this.statusCommentStartDate.subscribe(date => startDate = date)
    this.statusCommentEndDate.subscribe(date => endDate = date)
    this.filterForNoOperator.subscribe(noOperator => filterOperator = noOperator)
    let filter: StationFilterRepresentation = {
      searchString: this.searchString,
      regionalOffices: regOff,
      statusComments: statCom,
      transmissionSignals: transSig,
      statusCommentStartDate: startDate,
      statusCommentEndDate: endDate,
      filterForBatteryStatus: filterBattery.length > 0,
      filterForNoOperator: filterOperator.length > 0
    }
    this.storedCurrentPageSize.subscribe(pageSize => {
      if (pageSize !== undefined && pageSize !== null) {
        this.currentPageSize = pageSize;
      }
    });
    this.storedPageNo.subscribe(pageNo => this.pageNo = pageNo)
    this.stationsService.getStations(this.interval, this.pageNo, this.currentPageSize, filter)
      .subscribe(stationDashboard => {
        this.stationDashboard = stationDashboard;
        this.changeDetecterRef.detectChanges();
        this.isLoading = false;
        let elementId = ""
        this.elementToScroll.subscribe(elId => elementId = elId)
        this.dashboard = document.getElementById(elementId)
        console.log(this.dashboard)
        if (this.dashboard != null) {
          this.dashboard.scrollIntoView({block: 'center'})
        } else {
          this.store.dispatch(saveElementToScroll(""))
        }
      })
  }


  public mapTransmissionSignals(strings: string[]) {
    let transmissionSignals: string[] = []
    strings.map((transmissionSignal) => {
      switch (transmissionSignal) {
        case '100%-83%':
          transmissionSignals.push("OK")
          break;
        case '83%-25%':
          transmissionSignals.push("ERROR")
          break;
        case '25%-0%':
          transmissionSignals.push("OFFLINE")
          break;
        case 'inaktiv':
          transmissionSignals.push("NEW")
          break;
      }
    })
    return transmissionSignals
  }

  searchStations($event: string) {
    this.searchString = $event;
    this.store.dispatch(saveCurrentPageNo(0))
    this.store.dispatch(saveSearchString($event))
    this.store.dispatch(saveElementToScroll(""))
    this.updatePage();
  }

  openCSVImport() {
    this.dialog.open(RegionalOfficeImportComponent)
      .afterClosed().subscribe(() => this.updatePage())
  }

  pagingChange(event: { pageIndex: number; pageSize: number }) {
    this.store.dispatch(saveCurrentPageNo(event.pageIndex))
    this.store.dispatch(saveElementToScroll(""));
    this.store.dispatch(saveCurrentPageSize(event.pageSize))
    this.updatePage();

    this.dashboard = document.getElementById("stations");
    this.dashboard?.scrollIntoView(true)
  }

  updateFilter() {
    this.updatePage();
  }

  hasRequiredRole(requiredRole: Role): boolean {
    return this.userProviderService.hasRequiredRole(requiredRole);
  }
}
