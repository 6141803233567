import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject, EMPTY} from 'rxjs';
import {RegionalOfficeService} from '../regional-office-import/regional-office.service';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {StatusComment} from '../station-view/StatusComment';
import {select, Store} from '@ngrx/store';
import {
  saveBatteryState, saveCurrentPageNo, saveElementToScroll, saveEndDate, saveNoOperator,
  saveRegionalOffices, saveStartDate,
  saveStatusComments,
  saveTransmissionSignal
} from '../filterState/filter-state.actions';
import {
  selectBatteryState, selectEndDate, selectNoOperator,
  selectRegionalOffices, selectStartDate,
  selectStatusComments,
  selectTransmissionSignals
} from '../filterState/filter-state.selectors';
import {RegionalOffice} from '../model/RegionalOffice';

@Component({
  selector: 'met-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  regionalOfficeList: string[] = [];
  checkedRegionalOfficeList$ = this.store.pipe(select(selectRegionalOffices));
  checkedRegionalOfficeListSubject$ = new BehaviorSubject<string[]>([]);
  statusCommentList: string[] = ['Defekt', 'Messwert', 'Verbindung', 'Inaktiv'];
  connectionSignalList: string[] = ['100%-83%', '83%-25%', '25%-0%', 'inaktiv'];
  filterForBatteryStatus$ = this.store.pipe(select(selectBatteryState));
  filterForBatteryStatusSubject$ = new BehaviorSubject<string[]>([])

  @Output()
  filterChanged = new EventEmitter();

  startDate: Date | undefined = undefined;
  storeStartDate$ = this.store.pipe(select(selectStartDate))
  storeEndDate$ = this.store.pipe(select(selectEndDate))
  endDate: Date | undefined = undefined;
  checkedStatusCommentList$ = this.store.pipe(select(selectStatusComments));
  checkedStatusCommentListSubject$ = new BehaviorSubject<StatusComment[]>([]);

  checkedTransmissionSignalList$: Observable<string[]> = EMPTY;
  checkedTransmissionSignalListSubject$ = new BehaviorSubject<string[]>([]);

  resetSubject: Subject<void> = new Subject<void>();
  filterForNoOperator$ = this.store.pipe(select(selectNoOperator));
  filterForNoOperatorSubject$ = new BehaviorSubject<string[]>([]);

  constructor(public regionalOfficeService: RegionalOfficeService, private store: Store) {
  }

  ngOnInit(): void {
    this.checkedTransmissionSignalList$ = this.store.pipe(select(selectTransmissionSignals))
    this.storeStartDate$.subscribe(date => this.startDate = date)
    this.storeEndDate$.subscribe(date => this.endDate = date)
    const noBD: RegionalOffice = {
      name: 'Keine BD'
    };
    this.regionalOfficeService
    .getRegionalOffices(true)
    .subscribe(
      (regionalOffices) => {
        regionalOffices.push(noBD)
        this.regionalOfficeList = regionalOffices.map((office) => office.name)
        .filter((officeName) => officeName !== '')
      }
    );
  }

  ngOnDestroy() {
    this.resetSubject.next();
    this.resetSubject.complete();
    this.checkedTransmissionSignalListSubject$.unsubscribe();
    this.checkedStatusCommentListSubject$.unsubscribe();
    this.filterForBatteryStatusSubject$.unsubscribe();
    this.checkedRegionalOfficeListSubject$.unsubscribe();
    this.filterForNoOperatorSubject$.unsubscribe();
  }

  public onBatteryFilterChange(batteryStatus: string[]) {
    this.filterForBatteryStatusSubject$.next([]);
    if (batteryStatus.length > 0) {
      this.filterForBatteryStatusSubject$.next(["Schwache bis leere Batterie"]);
    }
  }

  public onRegionalOfficeChange(strings: string[]) {
    this.checkedRegionalOfficeListSubject$.next(strings);
  }

  public onStatusCommentChange(statusComments: StatusComment[]) {
    this.checkedStatusCommentListSubject$.next(statusComments);
  }

  public onConnectionSignalChange(strings: string[]) {
    this.checkedTransmissionSignalListSubject$.next(strings);
  }

  filterAssigned() {
    this.checkedTransmissionSignalList$ = this.checkedTransmissionSignalListSubject$.asObservable();
    this.checkedStatusCommentList$ = this.checkedStatusCommentListSubject$.asObservable();
    this.checkedRegionalOfficeList$ = this.checkedRegionalOfficeListSubject$.asObservable();
    this.filterForBatteryStatus$ = this.filterForBatteryStatusSubject$.asObservable();
    this.filterForNoOperator$ = this.filterForNoOperatorSubject$.asObservable();
    if (this.endDate != undefined) {
      this.endDate = new Date(this.endDate.setTime(this.endDate.getTime() + ((23 * 60 + 59) * 60 + 59) * 1000))
    }
    this.store.dispatch(saveRegionalOffices(this.checkedRegionalOfficeListSubject$.getValue()))
    this.store.dispatch(saveStatusComments(this.checkedStatusCommentListSubject$.getValue()))
    this.store.dispatch(saveTransmissionSignal(this.checkedTransmissionSignalListSubject$.getValue()))
    this.store.dispatch(saveBatteryState(this.filterForBatteryStatusSubject$.getValue()))
    this.store.dispatch(saveEndDate(this.endDate))
    this.store.dispatch(saveStartDate(this.startDate))
    this.store.dispatch(saveNoOperator(this.filterForNoOperatorSubject$.getValue()))
    this.filterChanged.emit();

  }

  setEndDate($event: MatDatepickerInputEvent<Date | null>) {
    if ($event.value != null) {
      this.endDate = $event.value
    }
  }

  setStartDate($event: MatDatepickerInputEvent<Date | null>) {
    if ($event.value != null) {
      this.startDate = $event.value
    }
  }

  resetFilter() {
    this.checkedStatusCommentListSubject$.next([])
    this.checkedStatusCommentList$ = this.checkedStatusCommentListSubject$.asObservable()
    this.checkedRegionalOfficeListSubject$.next([])
    this.checkedTransmissionSignalListSubject$.next([])
    this.filterForBatteryStatusSubject$.next([])
    this.filterForNoOperatorSubject$.next([])
    this.startDate = undefined
    this.endDate = undefined
    this.store.dispatch(saveStatusComments([]))
    this.store.dispatch(saveRegionalOffices([]))
    this.store.dispatch(saveTransmissionSignal([]))
    this.store.dispatch(saveStartDate(undefined))
    this.store.dispatch(saveEndDate(undefined))
    this.store.dispatch(saveBatteryState([]))
    this.store.dispatch(saveNoOperator([]))
    this.store.dispatch(saveElementToScroll(""))
    this.store.dispatch(saveCurrentPageNo(0))
    this.resetSubject.next();
    this.filterChanged.emit()
  }

  onNoOperatorChange(noOperatorStatus: string[]) {
    this.filterForNoOperatorSubject$.next([]);
    if (noOperatorStatus.length > 0) {
      this.filterForNoOperatorSubject$.next(["Kein Betreiber"]);
    }
  }
}
