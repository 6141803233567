import {Component, Input} from '@angular/core';
import {map, Subject, takeUntil, tap} from "rxjs";
import {Chart} from "angular-highcharts";
import {Options} from "highcharts";
import {PrecipitationChart} from "../helpers/PrecipitationChart";
import {SensorData, SensorService} from '../sensor.service';

@Component({
  selector: 'met-precipitation-chart',
  templateUrl: './precipitation-chart.component.html',
  styleUrls: ['./precipitation-chart.component.scss'],
})
export class PrecipitationChartComponent {
  constructor(private sensorService: SensorService) {}

  @Input()
  appId!: string;

  @Input()
  date!: Date;

  @Input()
  backDays!: number;

  private unsubscribe$ = new Subject<void>();

  chartDataPrecipitation: any = [];

  precipitationChart: Chart | undefined;

  now = new Date();

  convertDateTime(date: string) {
    return Date.parse(date);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getDataFromKachelmannApi();
  }

  private createCumulativeData(kachelmannObject: any[]): any[] {
    kachelmannObject.sort((a, b) => a.timestamp.localeCompare(b.timestamp));

    let data: any[] = [];
    let sum = 0;
    for (let i = 1; i < kachelmannObject.length; i++) {
      let j = kachelmannObject[i].value;
      if (j == undefined) {
        data.push([this.convertDateTime('' + kachelmannObject[i].timestamp), sum]);
      } else {
        sum += Number(j);
        data.push([this.convertDateTime('' + kachelmannObject[i].timestamp), sum]);
      }
    }
    return data;
  }

  private getDataFromKachelmannApi() {
    this.precipitationChart = new Chart(PrecipitationChart() as Options);


    this.sensorService
      .getSensorDataForPrecipitation(this.appId, this.backDays, this.now, 'PRECIPITATION_24h')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          return this.createCumulativeData(kachelmannObject);
        }),
        tap((chartData) => {
          if (chartData.length > 0) {
            this.precipitationChart?.addSeries(
              {type: 'spline', name: 'Niederschlag 24h', color: 'blue', data: chartData},
              true,
              false
            )
          }
        })
      )
      .subscribe();

    this.sensorService
      .getSensorDataForPrecipitation(this.appId, this.backDays, this.now, 'PRECIPITATION_1h')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          return this.createCumulativeData(kachelmannObject);
        }),
        tap((chartData) => {
          if (chartData.length > 0) {
            this.precipitationChart?.addSeries(
              {type: 'spline', name: 'Niederschlag 1h', color: 'black', data: chartData},
              true,
              false
            )
          }
        })
      )
      .subscribe();
  }
}
