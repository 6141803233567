import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {CommonModule, registerLocaleData} from '@angular/common';

registerLocaleData(localeDe, localeDeExtra);

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {PageLayoutComponent} from './page-layout/page-layout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {initializeKeycloak} from './init/keycloak-init.factory';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {StationsComponent} from './stations/stations.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {StatisticComponent} from './stations/statistic/statistic.component';
import {RegionalOfficeImportComponent} from './regional-office-import/regional-office-import.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ButtonComponent} from './button/button.component';
import {StationViewComponent} from './station-view/station-view.component';
import {TextInputComponent} from './text-input/text-input.component';
import {StationDashboardComponent} from './station-dashboard/station-dashboard.component';
import {FilterComponent} from './filter/filter.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SectionComponent} from './section/section.component';
import {DiagramsComponent} from './diagrams/diagrams.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {PointDiagramComponent} from './diagrams/point-diagram/point-diagram.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DialogComponent} from './dialog/dialog.component';
import {GetStatusCommentValue, ParseDate} from './util';
import {OperatorCardComponent} from './operator-card/operator-card.component';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import {HumidityChartComponent} from './diagrams/humidity-chart/humidity-chart.component';
import {TemperatureChartComponent} from './diagrams/temperature-chart/temperature-chart.component';
import {WindChartComponent} from './diagrams/wind-chart/wind-chart.component';
import {
  PrecipitationHourlyChartComponent
} from './diagrams/precipitation-hourly-chart/precipitation-hourly-chart.component';
import {PrecipitationChartComponent} from './diagrams/precipitation-chart/precipitation-chart.component';
import {EditStationComponent} from './edit-station/edit-station.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import exporting from 'highcharts/modules/exporting.src';
import windbarb from 'highcharts/modules/windbarb.src';


export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [ exporting,windbarb ];
}

import {ImagesComponent} from './images/images.component';
import {DateAdapter, MAT_DATE_LOCALE, MatOptionModule} from "@angular/material/core";
import {OperatorsComponent} from './operators/operators.component';
import {OperatorViewComponent} from './operator-view/operator-view.component';
import {EditOperatorFieldComponent} from './edit-operator-field/edit-operator-field.component';
import {OperatorStationViewComponent} from './operator-station-view/operator-station-view.component';
import {OperatorAddStationComponent} from './operator-add-station/operator-add-station.component';
import {ImageFullscreenViewerComponent} from './image-fullscreen-viewer/image-fullscreen-viewer.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SensorPipePipe} from './diagrams/sensor-pipe.pipe';
import {MatCardModule} from "@angular/material/card";
import {DialogCommentComponent} from './dialog-comment/dialog-comment.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {CustomDateAdapter} from './filter/CustomDateAdapter';
import {InformationStatusCreationComponent} from './information/information-status-creation/information-status-creation.component';
import {CreateNoteComponent} from './create-note/create-note.component';
import {InformationBannerComponent} from './information/information-banner/information-banner.component';
import {DialogSensorComponent} from './dialog-sensor/dialog-sensor.component';
import {ExplanationComponent} from './explanation/explanation.component';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {filterReducer} from './filterState/filter-state.reducer';
import {reducers, metaReducers} from './reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    PageLayoutComponent,
    StationsComponent,
    StatisticComponent,
    RegionalOfficeImportComponent,
    ButtonComponent,
    StationViewComponent,
    TextInputComponent,
    StationDashboardComponent,
    FilterComponent,
    SectionComponent,
    DiagramsComponent,
    PointDiagramComponent,
    DialogComponent,
    ParseDate,
    GetStatusCommentValue,
    PointDiagramComponent,
    HumidityChartComponent,
    OperatorCardComponent,
    TemperatureChartComponent,
    WindChartComponent,
    PrecipitationHourlyChartComponent,
    PrecipitationChartComponent,
    EditStationComponent,
    SensorPipePipe,
    ImagesComponent,
    OperatorsComponent,
    OperatorViewComponent,
    EditOperatorFieldComponent,
    OperatorStationViewComponent,
    OperatorAddStationComponent,
    ImageFullscreenViewerComponent,
    ConfirmationDialogComponent,
    DialogCommentComponent,
    InformationStatusCreationComponent,
    CreateNoteComponent,
    InformationBannerComponent,
    DialogSensorComponent,
    ExplanationComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    ChartModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatExpansionModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    EffectsModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreModule.forFeature('filterState', filterReducer),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },// add as factory to your providers
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    { provide: DateAdapter, useClass: CustomDateAdapter}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
