export enum RemarkType {
  NOTE = "NOTE",
  COMMENT = "COMMENT"
}

export namespace RemarkType {
  export function getValue(remarkType: RemarkType): RemarkType {
    if (remarkType == null) return RemarkType.NOTE
    switch (remarkType.toString()) {
      case "NOTE":
        return RemarkType.NOTE
      case "COMMENT":
        return RemarkType.COMMENT
      default:
        return RemarkType.NOTE
    }
  }
}
