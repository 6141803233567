import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InformationStatus, StatusType } from '../../model/InformationStatus';
import { InformationStatusStoreService } from '../information-status-store.service';
@Component({
  selector: 'app-information-status-creation',
  templateUrl: './information-status-creation.component.html',
  styleUrls: ['./information-status-creation.component.scss']
})
export class InformationStatusCreationComponent implements OnInit, OnDestroy {
  readonly statusType = StatusType;
  standardFieldIsActive: boolean;
  disturbanceText?: string;
  standardText?: string;
  @ViewChild('standardField') standardField?: ElementRef;
  @ViewChild('disturbanceField') disturbanceField?: ElementRef;
  private sub?: Subscription;

  constructor(private informationStatusStoreService: InformationStatusStoreService, private router: Router) {
    this.standardFieldIsActive = true;
  }

  ngOnInit(): void {
    this.loadMessage();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  loadMessage(): void {
    this.sub = this.informationStatusStoreService.informationStatuses$.subscribe(
      value => this.setInformationStatus(value)
    )
  }

  private setInformationStatus(value: InformationStatus[]): void {
    const okStatus = value.find(s => s.informationStatusType === StatusType.OK);
    this.standardText = okStatus?.message;

    const disturbanceStatus = value.find(s => s.informationStatusType === StatusType.DISTURBANCE);
    this.disturbanceText = disturbanceStatus?.message;

    this.activateTextfield(disturbanceStatus?.isActive ? StatusType.DISTURBANCE : StatusType.OK);
  }

  getDisabledStatus(statusType: StatusType): boolean {
    return statusType === StatusType.DISTURBANCE ? this.standardFieldIsActive : !this.standardFieldIsActive;
  }

  activateTextfield(statusType: StatusType) {
    this.standardFieldIsActive = statusType === StatusType.OK;
    if (this.disturbanceField) this.disturbanceField.nativeElement.disabled = statusType === StatusType.OK;
    if (this.standardField) this.standardField.nativeElement.disabled = statusType !== StatusType.OK;
  }

  cancel(): void {
    this.router.navigate(['']);
  }

  submit(): void {
    const data: InformationStatus[] = [{
      isActive: this.standardFieldIsActive,
      informationStatusType: StatusType.OK,
      message: this.standardText!
    },
    {
      isActive: !this.standardFieldIsActive,
      informationStatusType: StatusType.DISTURBANCE,
      message: this.disturbanceText!
    }];
    this.informationStatusStoreService.update(data);
  }
}
