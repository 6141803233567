<div class="row" style="display: flex">
  <met-button
    [isRaised]="selectedTimeInterval === 48"
    style="margin-right: 12px"
    [buttonText]="'48 Stunden'"
    (click)="timeIntervalChange(48)"
  ></met-button>
  <met-button
    style="margin-right: 12px"
    [isRaised]="selectedTimeInterval === 168"
    [buttonText]="'1 Woche'"
    (click)="timeIntervalChange(168)"
  ></met-button>
  <met-button
    [isRaised]="selectedTimeInterval === 672"
    [buttonText]="'4 Wochen'"
    (click)="timeIntervalChange(672)"
  ></met-button>
</div>
<div class="table-wrapper">
  <div class="header-wrapper" *ngIf="mappedStations.get(selectedTimeInterval) as stationData">
    <div *ngFor="let sensor of stationData">
      <div class="header-row">{{sensor.sensorType | sensorPipe }}</div>
    </div>
  </div>
  <div class="cell-wrapper" *ngIf="mappedStations.get(selectedTimeInterval) as stationData">
    <div class="cell-row" *ngFor="let sensor of stationData; let j = index">
      <div class="cell-point" *ngFor="let measuringPoint of sensor.measuringPoints; let i = index">
        <!--02:00 because of UTC time (GMT+2)-->
        <div class="border-line" *ngIf="convertDate(measuringPoint.timeStamp).includes('23:00')">
          <div></div>
        </div>
        <div *ngIf="j===0 && convertDate(measuringPoint.timeStamp).includes('12:00')"
             class="date-container">
          <div style="width: fit-content">
            {{ convertDateDayAndMonth(measuringPoint.timeStamp)}}
          </div>
        </div>
        <mat-icon
          class="mat-icon-green"
          matTooltip="{{ convertDate(measuringPoint.timeStamp) }}"
          matTooltipShowDelay="800"
          *ngIf="measuringPoint.value; else redCircle"
        >
          circle
        </mat-icon>
        <ng-template #redCircle>
          <mat-icon
            class="mat-icon-red"
            matTooltip="{{ convertDate(measuringPoint.timeStamp) }}"
            matTooltipShowDelay="800"
          >circle
          </mat-icon>
        </ng-template>
      </div>
    </div>
  </div>
</div>
