import { Component, OnInit } from '@angular/core';
import { Station } from '../model/Station';
import { ActivatedRoute, Router } from '@angular/router';
import { StationsService } from '../stations/stations.service';
import { MatTableDataSource } from '@angular/material/table';
import * as L from 'leaflet';
import { LatLng } from 'leaflet';
import { Sensor } from '../model/Sensor';
import { StationViewService } from './station-view.service';
import { StatusComment } from './StatusComment';
import {isOperatorEmtpy, Operator} from '../model/Operator';
import { EditStationComponent } from '../edit-station/edit-station.component';
import { RegionalOfficeService } from '../regional-office-import/regional-office.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { take } from 'rxjs';
import { Remark } from "../model/Remark";
import { CreateNoteComponent } from "../create-note/create-note.component";
import { formatDate } from '@angular/common';
import { UserRoleProviderService } from '../auth/user-role-provider-service';
import { Role } from '../model/Role';

@Component({
  selector: 'met-station-view',
  templateUrl: './station-view.component.html',
  styleUrls: ['./station-view.component.scss'],
})
export class StationViewComponent implements OnInit {
  stationId: string | null = '';
  station: Station | undefined;
  tableDatasource: MatTableDataSource<Station> = new MatTableDataSource();
  sensors: Sensor[] = [];
  //timeInterval in Days
  selectedTimeInterval: number = 7;
  show: number = 3;
  map: any;
  isGeoLocationDefined: boolean = true;
  isDisabled: boolean = true;
  readonly role = Role;

  editCreateOperator: string = "Bearbeiten"

  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
      shadowUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png',
    }),
  };

  address: string = '';
  displayedColumns: string[] = [];
  displayedColumnsLocation: string[] = [];
  columnsToDisplaySensor: string[] = [];
  span: boolean = true;
  statusComments: StatusComment[] = StatusComment.getPossibleValues();
  test: StatusComment = StatusComment.CONNECTION;
  operator: Operator =
    {
      operatorId: -1,
      vhOperatorId: "",
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      postCode: "",
      phoneNumber: "",
      email: "",
      stationMap: new Map<string, string>()
    };
  private regionalOffices: string[] = [];

  constructor(
    private stationService: StationsService,
    private route: ActivatedRoute,
    private router: Router,
    private stationViewService: StationViewService,
    public dialog: MatDialog,
    public regionalOfficeService: RegionalOfficeService,
    private userRoleProviderService: UserRoleProviderService) {
  }

  ngOnInit(): void {
    this.stationId = this.route.snapshot.paramMap.get('stationId');
    this.setColumns();
    this.setSensorColumns();
    this.setGeoColumns();
    this.loadStation();
    this.regionalOfficeService.getRegionalOffices(false)
      .subscribe(
        (regionalOffices) =>
          (this.regionalOffices = regionalOffices
            .map((office) => office.name)
            .filter((officeName) => officeName !== '')))
  }

  hasRequiredRole(requiredRole: Role): boolean {
    return this.userRoleProviderService.hasRequiredRole(requiredRole);
  }

  private setColumns(): void {
    const columns = ['stationStatus', 'stationId', 'appId', 'name', 'creationDate', 'type'];
    if (this.hasRequiredRole(this.role.regionaloffice_view)) {
      const cols = ['regionalOffice', 'contactPerson', 'gateWayPin'];
      columns.push(...cols);
    }
    this.displayedColumns = columns;
  }

  private setGeoColumns(): void {
    const columns = [];
    if (this.hasRequiredRole(this.role.geo_view)) {
      const cols = ['longitude', 'latitude', 'altitude'];
      columns.push(...cols);
    }
    this.displayedColumnsLocation = columns;
  }

  private setSensorColumns(): void {
    const columns = ['sensorId', 'sensorGroup', 'battery', 'sensorType', 'sensorTransmissionSignal', 'statusComment'];
    if (this.hasRequiredRole(this.role.notice_view)) {
      columns.push('note');
    }
    if (this.hasRequiredRole(this.role.comment_view)) {
      columns.push('comment');
    }
    this.columnsToDisplaySensor = columns;
  }

  private loadStation() {
    this.stationService.getStation(this.stationId).subscribe({
      next: station => {
        this.station = station;
        this.tableDatasource.data.push(station);
        this.tableDatasource._updateChangeSubscription();
        this.sensors = station.sensors;
        this.operator = station.operator;
        if(isOperatorEmtpy(this.operator)){
          this.editCreateOperator = "Hinzufügen"
        }
        this.station.notes = station.notes?.sort((a, b) => a.isArchived && !b.isArchived ? 1 : !a.isArchived && b.isArchived ? -1 : (a.creationDate <= b.creationDate ? 1 : -1))
        if (station.latitude != '' && station.longitude != '') {
          this.isGeoLocationDefined = true;
          this.initMap(station);
        } else {
          this.isGeoLocationDefined = false;
        }
      },
      error: err => {
        this.isGeoLocationDefined = false;
        console.error(err)
      }
    });
  }

  initMap(station: Station): void {
    this.map = L.map('map', {
      zoom: 14,
      center: [Number(station.latitude), Number(station.longitude)],
    });
    const arcGisURL = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
    const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });
    const arcAttribution =
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
    const arcGisSat = new L.TileLayer(arcGisURL, {maxZoom: 18, attribution: arcAttribution});

    const baseLayers = {
      Satellit: arcGisSat,
      Gelände: osm,
    };
    if (station.latitude != '' && station.longitude != '') {
      const marker = L.marker([Number(station.latitude), Number(station.longitude)], this.markerIcon);
      marker.addTo(this.map);
    }
    L.control.layers(baseLayers, {}).addTo(this.map);
    arcGisSat.addTo(this.map);
    this.map.setView(new LatLng(Number(station.latitude), Number(station.longitude)), 14);
    this.map.invalidateSize(true);
  }

  calcRowSpan(sensors: Sensor[], sensor: Sensor): number {
    let type = sensor.sensorGroup;
    let index = sensors.indexOf(sensor);
    if (index < sensors.length - 1) {
      if (sensors[index + 1].sensorGroup == type || sensors[index + 1].sensorGroup == '') {
        sensors[index + 1].sensorGroup = '';
        return 2;
      }
    }
    return 1;
  }

  setStatusComment(comment: StatusComment, sensor: Sensor) {
    if (this.stationId != null) {
      this.stationViewService
        .setSensorStatus(this.stationId, sensor, comment)
        // .pipe(take(1))
        .subscribe((item) => this.updateIfResponseIsOk(item));
    }
  }

  //TODO delete me ! When Setting Status note returns updated object
  private updateIfResponseIsOk(response: HttpResponse<Object>) {
    if (response.status === 200) this.getSensorData(this.selectedTimeInterval);
  }

  getSensorData(timeInterval: number) {
    this.selectedTimeInterval = timeInterval;
    if (this.stationId != null) {
      this.stationViewService
        .getFilteredSensorData(this.stationId, timeInterval)
        .pipe(take(1))
        .subscribe((sensors) => {
          this.sensors = sensors;
        });
    }
  }

  openStationDiagrams(value: string) {
    this.router.navigate(['diagrams', value]);
  }

  archiveRemarkNote(sensor: Sensor) {
    this.stationViewService.archiveRemarkNoteOnSensor(sensor);
  }

  archiveRemarkComment(sensor: Sensor) {
    this.stationViewService.archiveRemarkCommentOnSensor(sensor);
  }

  archiveSpecificNoteOnStation(station: Station, note: Remark) {
    this.stationViewService.archiveSpecificNoteOnStation(station, note);
  }

  openDialogNote(sensor: Sensor, canActivate = true) {
    if (canActivate) this.stationViewService.openDialogNoteOnSensor(sensor, this.stationId ? this.stationId : '');
  }

  openDialogNoteStation() {
    this.stationViewService.openDialogNoteOnStation(this.station!);
  }

  openSpecificOnNoteStation(station: Station, note: Remark) {
    this.stationViewService.openSpecificNoteOnStation(station, note);

  }

  openDialogComment(sensor: Sensor, canActivate = true) {
    if (canActivate) this.stationViewService.openDialogCommentOnSensor(sensor, this.stationId ? this.stationId : '')
  }

  openStationNote() {
    const dialogRef = this.dialog.open(CreateNoteComponent, {
      data: {
        stationId: this.stationId
      }
    });
    dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result.data.status == 200) {
          this.updateNotes(result.data.body)
        }
      })

  }

  openEditStation() {
    const dialogRef = this.dialog.open(EditStationComponent, {
      data: {
        station: this.station!,
        regionalOffices: this.regionalOffices
      }
    });
    dialogRef
      .afterClosed()
      .subscribe(station => {
        if (station != undefined) {
          this.updateStation(station.data)
        }
      })
  }

  updateStation(station: Station) {
    if (station != undefined) {
      this.station = station;
      this.tableDatasource.data.pop();
      this.tableDatasource.data.push(station);
      this.tableDatasource._updateChangeSubscription();
    }
  }

  updateNotes(remark: Remark) {
    this.station?.notes?.splice(0, 0, remark)
  }

  openEditOperator() {
    this.router.navigate(["operator-view", this.operator.operatorId, this.stationId, this.station?.name])
  }

  showMore() {
    this.show += 3;
  }

  setDate(creationDate: Date) {
    if (creationDate != undefined) {
      const timeZoneOffset = (-new Date().getTimezoneOffset()) / 60;
      creationDate = new Date(Date.parse(creationDate.toString()) + timeZoneOffset * 60 * 60 * 1000)
      return formatDate(creationDate.toString(), 'dd.MM.yyyy HH:mm', 'de-DE', 'CET')
    }
    return ''
  }
}
