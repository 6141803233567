import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Station} from '../model/Station';
import {Router} from '@angular/router';
import {Sensor} from '../model/Sensor';
import {StatusComment} from '../station-view/StatusComment';
import {take} from 'rxjs';
import {StationViewService} from '../station-view/station-view.service';
import {MatDialog} from '@angular/material/dialog';
import {UserRoleProviderService} from "../auth/user-role-provider-service";
import {Role} from '../model/Role';
import {Store} from '@ngrx/store';
import {saveCurrentPageNo, saveCurrentPageSize, saveElementToScroll} from '../filterState/filter-state.actions';

@Component({
  selector: 'met-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss'],
})
export class StationsComponent implements OnInit {
  statusComments: StatusComment[] = StatusComment.getPossibleValues()

  readonly role = Role;

  @Input()
  stations: Station[] = [];
  @Input()
  length: number = 0;

  @Input()
  pageNo: number = 0;
  @Input()
  currentPageSize: number = 0;

  storedCurrentPageSize: number = 150;

  @Output()
  pagingChanged = new EventEmitter<{ pageIndex: number; pageSize: number }>();
  @Input()
  pageSizes: number[] = [];

  onPageSizeChange(event: any) {
    this.pagingChanged.emit({pageIndex: event.pageIndex, pageSize: event.pageSize});
    this.storedCurrentPageSize = event.pageSize;
  }

  columnsToDisplayStation: string[] = [];
  columnsToDisplaySensorGroups: string[] = ['sensorGroup']

  constructor(private router: Router,
              private stationViewService: StationViewService,
              public dialog: MatDialog,
              private userRoleProviderService: UserRoleProviderService,
              private store: Store) {
  }

  ngOnInit(): void {
    this.setColumns();
  }

  private setColumns(): void {
    const columns = ['active', 'stationId', 'name', ];

    if(this.hasRequiredRole(this.role.regionaloffice_view)) {
      const regionalCols = ['regionalOffice', 'contactPerson']
      columns.push(...regionalCols);
    }
    if (this.hasRequiredRole(this.role.sensor_view)) {
      const sensorCols = ['sensorId', 'battery', 'sensorType', 'sensorTransmissionSignal', 'statusComment'];
      columns.push(...sensorCols);
    }
    if (this.hasRequiredRole(this.role.notice_view)){
      columns.push('note');
    }
    if (this.hasRequiredRole(this.role.comment_view)){
      columns.push('comment');
    }
    if (this.hasRequiredRole(this.role.image_view)){
      columns.push('images');
    }

    this.columnsToDisplayStation = columns;
  }

  openStationDetails(value: string, pageSize: number, pageNo: number) {
    this.store.dispatch(saveElementToScroll(value))
    this.store.dispatch(saveCurrentPageSize(pageSize))
    this.store.dispatch(saveCurrentPageNo(pageNo));
    this.router.navigate(["station-view", value])
  }

  calcRowSpan(station: Station, sensor: Sensor): number {
    let sensors = station.sensors
    let type = sensor.sensorGroup
    let index = sensors.indexOf(sensor)
    if (index < sensors.length - 1) {
      if (sensors[index + 1].sensorGroup == type || sensors[index + 1].sensorGroup == "") {
        let stationIndex = this.stations.indexOf(station)
        this.stations[stationIndex].sensors[index + 1].sensorGroup = ""
        this.stations[stationIndex].sensors[index + 1].sensorId = ""
        return 2
      }
    }
    return 1
  }

  checkIfItIsLastSensorRow(sensor: Sensor, station: Station) {
    return (station.sensors[station.sensors.length - 2] == sensor) && (station.sensors[station.sensors.length - 1].sensorGroup == '')
  }

  private emitValueChange() {
    this.pagingChanged.emit({pageIndex: this.pageNo, pageSize: this.currentPageSize});
  }

  setStatusComment(comment: StatusComment, stationId: string, sensor: Sensor) {
    if (stationId != null) {

      this.stationViewService.setSensorStatus(stationId, sensor, comment)
      .pipe(take(1))
      .subscribe(() => this.emitValueChange());
    }
  }

  archiveRemarkNoteOnSensor(sensor: Sensor) {
    this.stationViewService.archiveRemarkNoteOnSensor(sensor);
  }

  archiveRemarkCommentSensor(sensor: Sensor) {
    this.stationViewService.archiveRemarkCommentOnSensor(sensor);
  }

  openDialogNoteSensor(sensor: Sensor, stationId: string, canOpen = true) {
    if(canOpen) this.stationViewService.openDialogNoteOnSensor(sensor, stationId);
  }

  createNoteOnSensor(sensor: Sensor, stationId: string, canOpen = true) {
    if(canOpen) this.stationViewService.createNoteOnSensor(sensor, stationId);
  }

  openDialogCommentSensor(sensor: Sensor, stationId: string, canOpen = true) {
    if (canOpen) this.stationViewService.openDialogCommentOnSensor(sensor, stationId);
  }

  archiveRemarkNoteOnStation(station: Station) {
    this.stationViewService.archiveRemarkNoteOnStation(station);
  }

  archiveRemarkCommentStation(station: Station) {
    this.stationViewService.archiveRemarkCommentOnStation(station);
  }

  openDialogNoteStation(station: Station, canOpen = true) {
    if(canOpen) this.stationViewService.openDialogNoteOnStation(station);
  }

  createNoteOnStation(station: Station, canOpen = true) {
    if(canOpen) this.stationViewService.createNoteOnStation(station);
  }

  openDialogCommentStation(station: Station, canOpen = true) {
    if(canOpen) this.stationViewService.openDialogCommentOnStation(station);
  }

  hasRequiredRole(requiredRole: Role): boolean {
    return this.userRoleProviderService.hasRequiredRole(requiredRole);
  }

}
