<div>
  <h4>Betreiber</h4>
</div>
<div class="vCard">
  <div class="address-row" *ngIf="operator.firstName || operator.lastName">
    <div class="left-card" *ngIf="operator.firstName">
      {{operator.firstName}}
    </div>
    <div *ngIf="operator.lastName">
      {{operator.lastName}}
    </div>
  </div>
  <div class="address-row" *ngIf="operator.street">
    <div>
      {{operator.street}}
    </div>
  </div>
  <div class="address-row" *ngIf="operator.postCode || operator.phoneNumber">
    <div class="left-card" *ngIf="operator.postCode">
      {{operator.postCode}}
    </div>
    <div *ngIf="operator.city">
      {{operator.city}}
    </div>
  </div>
  <div class="address-row" *ngIf="operator.email">
    <a [href]="openEmail()">
      {{operator.email}}
    </a>
  </div>
  <div class="address-row" *ngIf="operator.phoneNumber">
    <div>
      {{operator.phoneNumber}}
    </div>
  </div>
  <div class="address-row" *ngIf="emptyOperatorCheck(operator)">
    <div>
      Es liegen keine Kontaktinformationen vor!
    </div>
  </div>
</div>
