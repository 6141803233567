import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'met-operator-station-view',
  templateUrl: './operator-station-view.component.html',
  styleUrls: ['./operator-station-view.component.scss']
})
export class OperatorStationViewComponent {

  @Input()
  stationId: string = '';

  @Input()
  stationName: any = '';

  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>()

  constructor() {
  }

  deleteStation() {
    this.delete.emit()
  }
}
