import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as moment from 'moment';
import {MeasuringPoint} from 'src/app/model/Sensor';
import {DaysToHours} from '../daysToHours.enum';
import {formatDate} from '@angular/common';

@Component({
  selector: 'met-point-diagram',
  templateUrl: './point-diagram.component.html',
  styleUrls: ['./point-diagram.component.scss'],
})
export class PointDiagramComponent {
  @Input()
  mappedStations!: Map<DaysToHours, Array<{sensorType: string; inactive: boolean; measuringPoints: MeasuringPoint[]}>>;

  @Input()
  selectedTimeInterval!: DaysToHours;

  @Output()
  switchTimeInterval = new EventEmitter<DaysToHours>();

  timeIntervalChange(interval: DaysToHours) {
    this.switchTimeInterval.emit(interval);
  }

  convertDate(date: string): String {
    let newDate = new Date(date);
    return moment(newDate).locale('de').format('llll');
  }

  convertDateDayAndMonth(date: string): String {
    return formatDate(date,'dd.MM','de','utc')
  }
}
