import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { StatusComment } from './station-view/StatusComment';

export class Util {

}


@Pipe({
  name: 'parseDate'
})
export class ParseDate implements PipeTransform {
  transform(value: string) {
    if (value == null || value.trim().length == 0) {
      return ""
    }
    return formatDate(value, "dd.MM.yyyy", 'DE')
  }
}

@Pipe({
  name: 'getStatusCommentValue'
})
export class GetStatusCommentValue implements PipeTransform {
  transform(value: StatusComment): StatusComment {
    return StatusComment.getValue(value)
  }
}



