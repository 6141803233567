import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'met-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent {

  @Input()
  searchString: string = "";

  @Input()
  placeHolderText: string = "";
  @Input()
  inputName: string = "";
  @Input()
  deleteButtonId: string = "";

  @Output() searchStringChange = new EventEmitter<string>()

  commitChange(searchStringChange=this.searchString) {
    this.searchString = searchStringChange;
    this.searchStringChange.emit(this.searchString)
  };

  deleteSearchString() {
    this.searchString = ""
    this.commitChange()
  }
}
