import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OperatorsService} from '../operators/operators.service';
import {isOperatorEmtpy, Operator} from '../model/Operator';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {OperatorAddStationComponent} from '../operator-add-station/operator-add-station.component';
import {HttpStatusCode} from '@angular/common/http';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'met-operator-view',
  templateUrl: './operator-view.component.html',
  styleUrls: ['./operator-view.component.scss']
})
export class OperatorViewComponent implements OnInit {
  operatorId: string | null = '';
  operator: Operator =
    {
      operatorId: 0,
      vhOperatorId: "",
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      postCode: "",
      phoneNumber: "",
      email: "",
      stationMap: new Map<string, string>
    };
  private stations: Map<string, string> = new Map<string, string>;
  status: boolean = false;
  private isNewOperator: boolean = true;

  deletable: boolean = false;
  private rootStationId: string | null = null;
  private rootStationName: string | null = null;

  private dialogRef: MatDialogRef<ConfirmationDialogComponent> | undefined;

  constructor(
    private route: ActivatedRoute, private operatorService: OperatorsService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit(): void {
    this.operatorId = this.route.snapshot.paramMap.get('operatorId')
    this.rootStationId = this.route.snapshot.paramMap.get('stationId')
    this.rootStationName = this.route.snapshot.paramMap.get('stationName')
    this.operatorService.searchStations('').subscribe(
      stations => {
        this.stations = new Map(Object.entries(stations))
      }
    )
    if (this.rootStationId != null && this.rootStationName != null) {
      this.operator.stationMap.set(this.rootStationId, this.rootStationName)
      this.deletable = this.operator.stationMap.size > 0
    }
    if (this.operatorId != null && this.operatorId != "0") {
      this.operatorService.getOperator(parseInt(this.operatorId)).pipe().subscribe(
        operator => {
          this.operator = operator
          this.operator.stationMap = new Map(Object.entries(operator.stationMap))
          this.isNewOperator = isOperatorEmtpy(this.operator)
          this.checkMap()
        }
      )
    }
  }

  changeValue(property: string, $event: string) {
    this.status = false
    switch (property) {
      case "id":
        this.operator.vhOperatorId = $event
        break;
      case "firstName" :
        this.operator.firstName = $event
        break;
      case "lastName" :
        this.operator.lastName = $event
        break;
      case "phoneNumber" :
        this.operator.phoneNumber = $event
        break;
      case "email" :
        this.operator.email = $event
        break;
      case "street" :
        this.operator.street = $event
        break;
      case "postCode" :
        this.operator.postCode = $event
        break;
      case "city" :
        this.operator.city = $event
        break;
    }
  }

  save() {
    console.log(this.operator)
    if (!this.isNewOperator) {
      this.operatorService.saveOperator(this.operator).subscribe(
        result => {
          this.status = result.status == HttpStatusCode.Ok;
          this.operator.stationMap = new Map(Object.entries(this.operator.stationMap))
          this.checkMap()
        }
      )
    } else {
      console.log("new")
      console.log(this.operator)
      this.operatorService.addOperator(this.operator).subscribe(
        result => {
          this.status = result.status == HttpStatusCode.Created
          this.operator.stationMap = new Map(Object.entries(this.operator.stationMap))
          this.checkMap()
        }
      )
    }
  }

  deleteStation(stationKey: string) {
    this.status = false
    console.log(this.operator.stationMap)
    this.operator.stationMap.delete(stationKey)
  }

  openAddStation() {
    console.log(this.stations)
    this.status = false
    this.dialog.open(OperatorAddStationComponent, {
      data: {
        stations: this.stations
      }
    })
    .afterClosed().subscribe(stationName => {
      if (stationName != undefined) {
        for (let [key, value] of this.stations.entries()) {
          if (value === stationName.data) {
            this.operator.stationMap.set(key, value)
            return
          }
        }
      }
    })
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = "Möchten Sie den Betreiber wirklich löschen?";

    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result)
        this.operatorService.deleteOperator(this.operator.operatorId).subscribe(result => {
          if(result) {
            this.router.navigate(["operators"]);
          }
        });
      }
      this.dialogRef = undefined;
    });
  }

  checkMap() {
    this.deletable = this.operator.stationMap.size < 1
  }
}
