import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Operator} from '../model/Operator';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {

  baseUrl = '/operator/'

  constructor(private http: HttpClient) {
  }

  getAllOperators(pageNo: number, pageSize: number, searchString: string) {
    return this.http.get<Operator[]>(environment.API_URL + this.baseUrl + '?pageNo=' + pageNo
      + '&pageSize=' + pageSize + '&searchString=' + searchString)
  }

  countOperators(searchString: string) {
    return this.http.get<number>(environment.API_URL + this.baseUrl + 'count?searchString=' + searchString);
  }

  getOperator(operatorDbId: number) {
    return this.http.get<Operator>(environment.API_URL + this.baseUrl + 'find?operatorDbId=' + operatorDbId);
  }

  saveOperator(operator: Operator) {
    const map = Object.create(null);
    operator.stationMap.forEach((val: string, key: string) => {
      map[key] = val
    })
    operator.stationMap = map
    return this.http.post<Operator>(environment.API_URL + this.baseUrl, operator, {observe: 'response'})
  }

  searchStations(searchString: String) {
    return this.http.get<Map<string, string>>(environment.API_URL + '/station/search?searchString=' + searchString)
  }

  addOperator(operator: Operator) {
    console.log(operator)
    const map = Object.create(null);
    operator.stationMap.forEach((val: string, key: string) => {
      map[key] = val
    })
    operator.stationMap = map
    console.log(operator)
    return this.http.post<Operator>(environment.API_URL + this.baseUrl + "new", operator, {observe: 'response'});
  }

  deleteOperator(operatorId: number) {
    console.log(operatorId)
    return this.http.delete<boolean>(environment.API_URL + this.baseUrl +"?operatorId=" + operatorId)
  }
}
