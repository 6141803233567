import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../environments/environment';

export const initializeKeycloak =
  (keycloakService: KeycloakService): (() => Promise<boolean>) =>
  () => {
    if (!environment.auth.use_dummy_auth) {
      return keycloakService.init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        },
        bearerExcludedUrls: ['/assets'],
      });
    } else {
      return Promise.resolve(true);
    }
  };
