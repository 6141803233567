<div mat-dialog-content>
  <p>Notiz bearbeiten</p>
  <mat-form-field appearance="fill">
    <mat-label>Notiz</mat-label>
    <input maxlength="1000" #titleRef matInput [(ngModel)]="data">
    <div class="counter">{{1000 - titleRef.value.length}}</div>
  </mat-form-field>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-between">
  <met-button (click)="onNoClick()" buttonText="Abbrechen"></met-button>
  <met-button [mat-dialog-close]="data" buttonText="Speichern"></met-button>
</div>

