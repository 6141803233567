<div>
  <div class="banner">
    <app-information-banner></app-information-banner>
  </div>
  <mat-toolbar style="background-color:
                 var(--background-color)" class="navBar">
    <a class="toolbar-style" [id]="'/logout'" (click)="logout()">
      <span>Ausloggen</span>
    </a>
    <a class="toolbar-style" [id]="'/support'" (click)="openSupport()">
      <span>Support kontaktieren</span>
    </a>
    <span class="example-spacer"></span>
    <button [id]="'burger-menu'" mat-icon-button class="example-icon" (click)="sideMenu.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>
</div>
<div>
  <mat-sidenav-container>
    <mat-sidenav #sideMenu position="end" [autoFocus]="false"
                 class="border-transparent" style="background-color:
                 var(--background-color)">
      <mat-nav-list class="flex-menu" style="display: flex; flex-direction: column">
        <a mat-list-item style="margin-top: 0"
           [id]="'/stations'" [routerLink]="'/stations'" routerLinkActive="active-list-item">
          <h4 mat-line>Stationen</h4>
          <span mat-line></span>
        </a>
        <a mat-list-item class="menu-headline"
           [id]="" [routerLink]="'/operators'" routerLinkActive="active-list-item">
          <h4 mat-line>Betreiber</h4>
          <span mat-line></span>
        </a>
        <a *ngIf="hasRequiredRole(role.information_status_edit)" mat-list-item class="menu-headline"
           [id]="" [routerLink]="'/information'" routerLinkActive="active-list-item">
          <h4 mat-line>Hinweise bei Störung</h4>
          <span mat-line></span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <div class="content">
      <main>
        <div>
          <ng-content></ng-content>
        </div>
      </main>
    </div>
  </mat-sidenav-container>

</div>
