<met-button [matMenuTriggerFor]="menu" buttonText="Filtern nach"></met-button>
<mat-menu #menu="matMenu">
  <div class="content-wrapper">
    <met-section [valueList]="['Schwache bis leere Batterie']" [resetEvent]="resetSubject"
                 [title]="'Batterie'" (valueChanged)="onBatteryFilterChange($event)"
                 [checkedInputValues]="filterForBatteryStatus$"></met-section>
    <met-section [valueList]="connectionSignalList" [title]="'Übertragungssignal'"
                 [resetEvent]="resetSubject" (valueChanged)="onConnectionSignalChange($event)"
                 [checkedInputValues]="checkedTransmissionSignalList$"></met-section>
    <met-section [valueList]="statusCommentList" [title]="'Status-Hinweis'"
                 (valueChanged)="onStatusCommentChange($event)" [resetEvent]="resetSubject"
                 [checkedInputValues]="checkedStatusCommentList$"></met-section>
    <div class="range" (click)="$event.stopPropagation()">
      <div>
        <p>von: </p>
      </div>
      <div class="date-input">
        <div class="input-style">
          <input matInput [matDatepicker]="pickerStart" [ngModel]="startDate" (dateInput)="setStartDate($event)">
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart></mat-datepicker>
        </div>
      </div>
    </div>
    <div class="range" (click)="$event.stopPropagation()">
      <div>
        <p>bis: </p>
      </div>
      <div class="date-input">
        <div class="input-style">
          <input matInput [matDatepicker]="pickerEnd" [ngModel]="endDate" (dateInput)="setEndDate($event)">
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd></mat-datepicker>
        </div>
      </div>
    </div>
    <met-section
      [valueList]="regionalOfficeList"
      [title]="'BD/NL'"
      (valueChanged)="onRegionalOfficeChange($event)" [resetEvent]="resetSubject"
      [checkedInputValues]="checkedRegionalOfficeList$"
    ></met-section>
    <met-section
      [valueList]="['Kein Betreiber']"
      [title]="'Betreiber'"
      (valueChanged)="onNoOperatorChange($event)" [resetEvent]="resetSubject"
      [checkedInputValues]="filterForNoOperator$"
    ></met-section>
  </div>
  <div class="menu-buttons">
    <met-button [buttonText]="'Zurücksetzen'" (click)="resetFilter()"></met-button>
    <met-button [buttonText]="'Filtern'" (click)="filterAssigned()"></met-button>
  </div>
</mat-menu>
