<ng-container *ngIf="activeInformationStatus">
    <div [ngClass]="isHidden && isOkMessage(activeInformationStatus) ? 'banner-inactive' : 'banner-active'">
        <ng-container *ngIf="isOkMessage(activeInformationStatus); else disturbance">
            <ng-container *ngIf="!isHidden; else hidden">
                <div id="message" class="info ok">
                  <div style="padding-left: 1rem">
                    {{ activeInformationStatus.message }}
                  </div>
                </div>
                <button mat-icon-button (click)="toggleBanner()" class="overflown active">
                    <mat-icon>
                        expand_less
                    </mat-icon>
                </button>
            </ng-container>
            <ng-template #hidden>
                <button mat-icon-button (click)="toggleBanner()" class="overflown inactive">
                    <mat-icon>
                        expand_more
                    </mat-icon>
                </button>
            </ng-template>
        </ng-container>
        <ng-template #disturbance>
            <div class="info disturbance">
                <mat-icon>
                    warning
                </mat-icon>
              <div style="padding-left: 1rem">
                {{ activeInformationStatus.message }}
              </div>
            </div>
        </ng-template>
    </div>
</ng-container>
