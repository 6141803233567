export enum StatusComment {
  NONE = "---",
  DEFECT = "Defekt",
  MEASUREMENT = "Messwert",
  CONNECTION = "Verbindung",
  INACTIVE = "Inaktiv"

}

export namespace StatusComment {
  export function getValue(statusComment: StatusComment): StatusComment {
    if (statusComment == null) return StatusComment.NONE
    switch (statusComment.toString()) {
      case "DEFECT":
        return StatusComment.DEFECT
      case "CONNECTION":
        return StatusComment.CONNECTION
      case "MEASUREMENT":
        return StatusComment.MEASUREMENT
      case "INACTIVE":
        return StatusComment.INACTIVE
      default:
        return StatusComment.NONE
    }
  }

  export function getPossibleValues(): StatusComment[] {
    return [
      StatusComment.NONE,
      StatusComment.DEFECT,
      StatusComment.CONNECTION,
      StatusComment.MEASUREMENT,
      StatusComment.INACTIVE
    ];
  }
   export function getKeyByValue(value: string) {
    const indexOfS = Object.values(StatusComment).indexOf(value as unknown as StatusComment);

    return Object.keys(StatusComment)[indexOfS];
  }
}
