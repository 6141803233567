import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Station } from '../model/Station';
import { environment } from '../../environments/environment';
import { StationDashboardRepresentation } from '../station-dashboard/StationDashboardRepresentation';
import { StationFilterRepresentation } from './StationFilterRepresentation';

@Injectable({
  providedIn: 'root',
})
export class StationsService {

  baseUrl = '/station/'

  constructor(private http: HttpClient) {
  }

  getStationQuantity(search: string) {
    return this.http.get<number>(environment.API_URL + this.baseUrl + 'stationQuantity?search=' + search)
  }

  getStations(interval: number, pageNo: number, pageSize: number, filter: StationFilterRepresentation) {
    return this.http.post<StationDashboardRepresentation>(environment.API_URL
      + this.baseUrl + '?interval=' + interval + '&pageNo=' + pageNo + '&pageSize=' + pageSize,
      filter, { })
  }

  getStation(stationId: string | null) {
    return this.http.get<Station>(environment.API_URL + this.baseUrl + 'find?stationId=' + stationId)
  }

}
