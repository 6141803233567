import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogComponent} from "../dialog/dialog.component";
import {StationViewService} from "../station-view/station-view.service";
import {Remark} from "../model/Remark";
import {RemarkType} from "../model/RemarkType";

@Component({
  selector: 'met-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss']
})
export class CreateNoteComponent implements OnInit {


  ngOnInit(): void {
    this.notes = this.data.notes;
  }

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private stationViewService: StationViewService) {
  }


  notes: Remark[] = [];
  sensorType: string = '';
  stationId: string = '';
  text: string = '';


  onNoClick(): void {
    this.dialogRef.close();
  }


  onSave(): void {
    this.stationId = this.data.stationId
    this.stationViewService
      .setRemark(RemarkType.NOTE, this.stationId, this.text, this.sensorType).subscribe(station => {
      this.dialogRef.close({data: station})
    })
  }

}
