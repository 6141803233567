import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {startWith, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StationViewService} from '../station-view/station-view.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';


@Component({
  selector: 'met-edit-station',
  templateUrl: './edit-station.component.html',
  styleUrls: ['./edit-station.component.scss']
})

export class EditStationComponent implements OnInit {
  ngOnInit(): void {
    this.stationId = this.data.station.stationId
    if (this.data.station.gateWayPin != null) {
      this.gateWayPin = this.data.station.gateWayPin
    }
    if (this.data.station.contactPerson != null) {
      this.contactPerson = this.data.station.contactPerson
    }
    this.regionalOfficeName = this.data.station.regionalOfficeRepresentation.name
    this.regionalOffices = this.data.regionalOffices
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private stationViewService: StationViewService) {
  }

  regionalOffices: string[] = [];
  control = new FormControl('');
  contactPerson: string = '';
  gateWayPin: string = '';
  stationId: string = '';
  regionalOfficeName: string = '';
  filteredOptions: Observable<string[]> | undefined;

  onNoClick(): void {
    this.dialogRef.close();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.regionalOffices.filter(regionalOffice => regionalOffice.toLowerCase().includes(filterValue));
  }

  onSave(): void {
    this.stationViewService
    .editStation(this.stationId, this.regionalOfficeName,
      this.contactPerson, this.gateWayPin).subscribe(station => {
      this.dialogRef.close({data: station})
    })
  }

  setRegionalOfficeName(regionalOffice: MatAutocompleteSelectedEvent) {
    this.regionalOfficeName = regionalOffice.option.value
  }
}
