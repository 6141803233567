<div class="fileInput">
  <h3>Import BD/NL</h3>
  <h5>CSV-Datei hochladen</h5>
  <div class="row">
    <div class="input">
      <met-button [buttonText]="'CSV-Datei auswählen'" (click)="fileDropRef.click()"></met-button>
      <div class="file-name">{{fileName}}</div>
    </div>
    <input style="display: none" type="file" #fileDropRef id="fileDropRef" (change)="handleInput(fileDropRef.files)"
           accept="text/csv">
    <div class="row">
      <div class="button-style">
        <met-button [buttonText]="'Abbrechen'" (click)="closeDialog()"></met-button>
      </div>
      <div class="button-style">
        <met-button [buttonText]="'Datei Hochladen'" id="uploadFileButton" (click)="uploadFile()"></met-button>
      </div>
    </div>
  </div>
  <div class="row">
    <div>
      <label>{{process}}</label>
    </div>
    <div>
      <label>{{result}}</label>
    </div>
  </div>
</div>
