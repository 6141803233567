import {createAction} from '@ngrx/store';
import {StatusComment} from '../station-view/StatusComment';

export const saveRegionalOffices = createAction(
  '[FilterState] saveRegionalOffices',
  (regionalOffices: string[]) => ({regionalOffices})
);

export const saveBatteryState = createAction(
  '[FilterState] saveBatteryState',
  (filterForBatteryState: string[]) => ({filterForBatteryState})
);

export const saveNoOperator = createAction(
  '[FilterState] saveNoOperator',
  (filterForNoOperator: string[]) => ({filterForNoOperator})
);

export const saveStatusComments = createAction(
  '[FilterState] saveStatusComments',
  (statusComments: StatusComment[]) => ({statusComments})
);

export const saveTransmissionSignal = createAction(
  '[FilterState] saveTransmissionSignal',
  (transmissionSignals: string[]) => ({transmissionSignals})
);

export const saveStartDate = createAction(
  '[FilterState] saveStartDate',
  (startDate: Date | undefined) => ({startDate})
);

export const saveEndDate = createAction(
  '[FilterState] saveEndDate',
  (endDate: Date | undefined) => ({endDate})
);

export const saveCurrentPageNo = createAction(
  '[FilterState] saveCurrentPageNo',
  (currentPageNumber: number) => ({currentPageNumber})
);

export const saveElementToScroll = createAction(
  '[FilterState] saveElementToScroll',
  (elementToScroll: string) => ({elementToScroll})
);

export const saveCurrentPageSize = createAction(
  '[FilterState] saveCurrentPageSize',
  (currentPageSize: number) => ({currentPageSize})
);

export const saveSearchString = createAction(
  '[FilterState] saveSearchString',
  (searchString: string) => ({searchString})
);
