import {Component, Input} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Options} from 'highcharts';
import {forkJoin, map, Observable, Subject, takeUntil, tap} from 'rxjs';
import {WindChart} from '../helpers/WindChart';

import {SensorData, SensorService} from '../sensor.service';

interface MappedWindData {
  windSpeed: SensorData[];
  windDirection: SensorData[];
}
/**
 * windSpeedDirectionMap = [timestamp, windspeed, winddirection]
 */
@Component({
  selector: 'met-wind-chart',
  templateUrl: './wind-chart.component.html',
  styleUrls: ['./wind-chart.component.scss'],
})
export class WindChartComponent {
  constructor(private sensorService: SensorService) {}

  @Input()
  appId!: string;

  @Input()
  date!: Date;

  @Input()
  backDays!: number;

  private unsubscribe$ = new Subject<void>();

  chartDataGust: any = [];

  windChart: Chart | undefined;

  now = new Date();

  convertDateTime(date: string) {
    return Date.parse(date);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getDataFromKachelmannApi();
  }

  private filterPoints(kachelmannObject: any[], n: number): any[] {
    let data = [];
    for (let i = 0; i < kachelmannObject.length; i += n) {
      data.push(kachelmannObject[i]);
    }
    return data;
  }

  private getDataFromKachelmannApi() {
    this.windChart = new Chart(WindChart() as Options);

    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'WINDGUST')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          return kachelmannObject.map((item: any) => [this.convertDateTime(item.timestamp), Number(item.value)]);
        }),
        tap((chartData) => {
          this.windChart?.addSeries(
            {
              type: 'spline',
              name: 'Windböe',
              color: 'brown',
              data: chartData,
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x:%e. %b, %H:%M} <br> {point.y:.2f} m/s',
              },
            },
            true,
            false
          );
        })
      )
      .subscribe();

    const windspeedDirection$ = forkJoin({
      windSpeed: this.sensorService.getSensorData(this.appId, this.backDays, this.now, 'WINDSPEED').pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) =>
          kachelmannObject.map((item: any) => ({
            timestamp: this.convertDateTime(item.timestamp),
            value: Number(item.value),
          }))
        )
      ),

      windDirection: this.sensorService.getSensorData(this.appId, this.backDays, this.now, 'WIND_DIR').pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) =>
          kachelmannObject.map((item: any) => ({
            timestamp: this.convertDateTime(item.timestamp),
            value: Number(item.value),
          }))
        )
      ),
    })
      .pipe(
        map((mappedWindData: MappedWindData) =>
          mappedWindData.windSpeed
            .map((windSpeed) => {
              const windDirection = mappedWindData.windDirection.filter((o1) => windSpeed.timestamp === o1.timestamp);

              if (windDirection.length === 1) {
                return {
                  timestamp: windSpeed.timestamp,
                  windspeed: windSpeed.value,
                  winddirection: windDirection[0].value,
                };
              } else {
                return null;
              }
            })
            .filter(
              (element) =>
                element !== null &&
                element.timestamp !== null &&
                element.windspeed !== null &&
                element.winddirection !== null
            )
        ),
        tap((mappedWindData) => {
          this.windChart?.addSeries(
            {
              type: 'spline',
              name: 'Windgeschwindigkeit',
              color: 'green',
              data: mappedWindData.map((data) => [data?.timestamp, data?.windspeed]),
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x:%e. %b, %H:%M} <br> {point.y:.2f} m/s',
              },
            },
            true,
            false
          );
          this.windChart?.addSeries(
            {
              type: 'windbarb',
              name: 'Windrichtung',
              color: 'black',
              keys: ['x', 'value', 'direction'],
              data: this.filterPoints(
                mappedWindData.map((data) => [data?.timestamp, data?.windspeed, data?.winddirection]),
                3
              ),
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x:%e. %b, %H:%M} <br> {point.direction:.f} degree',
              },
            },
            true,
            false
          );
        })
      )
      .subscribe();
  }
}
