<met-page-layout>
  <form id="stations">
    <div>
      <div>
        <met-explanation></met-explanation>
      </div>
      <div class="header" *ngIf="hasRequiredRole(role.stationstatistic_view)">
        <met-statistic [stationStatistic]="stationDashboard.stationStatisticsRepresentation"></met-statistic>
      </div>
    </div>
    <div class="menu">
      <div class="filter">
        <div>
          <met-filter (filterChanged)="updateFilter()"></met-filter>
        </div>
      </div>
      <div class="stationFilter" *ngIf="hasRequiredRole(role.sensor_view)">
        <div class="stationFilterItem">
          <met-button [buttonText]="'48 Stunden'" (click)="setIntervalAndUpdate(2)"
                      [isLoading]="isLoading && interval === 2"
                      [isRaised]="interval===2">
          </met-button>
        </div>
        <div class="stationFilterItem">
          <met-button [buttonText]="'1 Woche'" (click)="setIntervalAndUpdate(7)"
                      [isLoading]="isLoading && interval === 7"
                      [isRaised]="interval===7">
          </met-button>
        </div>
        <div class="stationFilterItem">
          <met-button [buttonText]="'4 Wochen'" (click)="setIntervalAndUpdate(28)"
                      [isLoading]="isLoading && interval === 28"
                      [isRaised]="interval===28">
          </met-button>
        </div>
      </div>
      <div class="search">
        <div>
          <met-text-input deleteButtonId="deleteButton" placeHolderText="Suchfeld"
                          inputName="search" (searchStringChange)="searchStations($event)"
                          [(searchString)]="searchString"></met-text-input>
        </div>
        <div>
          <met-button [buttonText]="'Suchen'" (click)="searchStations(searchString)" autofocus></met-button>
        </div>
        <div class="help-icon">
          <mat-icon [matTooltip]="searchHelp">help</mat-icon>
        </div>
      </div>
      <div *ngIf="hasRequiredRole(role.bd_nl_import)" class="import">
        <met-button icon="arrow_upward" [buttonText]="'Import BD/NL'" id="buttonRegionalOfficeImport"
                    (mousedown)="openCSVImport()">
        </met-button>
      </div>
    </div>
    <div>
      <met-stations [stations]="stationDashboard.stationRepresentations"
                    [length]="stationDashboard.stationStatisticsRepresentation.installedStations"
                    [pageNo]="pageNo"
                    [currentPageSize]="currentPageSize"
                    (pagingChanged)="pagingChange($event)"
                    [pageSizes]="pageSizes"
      ></met-stations>
    </div>
  </form>
</met-page-layout>
