<div class="statistic">
  <div class="statistic-item">
    <label>Gesamt:</label>
    <label class="numberLabel" name="numberOfStations">{{stationStatistic.installedStations}}</label>
    <label>Stationen</label>
  </div>
  <div class="statistic-item">
    <label>Inaktiv:</label>
    <label class="numberLabel" name="inactiveStations">{{stationStatistic.inactiveStations}}</label>
    <label>Stationen</label>
  </div>
  <div class="statistic-item">
    <label>Fehlerfrei:</label>
    <label class="numberLabel" name="errorFreeStations">{{stationStatistic.errorFreeStations}}</label>
    <label>Stationen</label>
  </div>
  <div class="statistic-item">
    <label>Fehlerhaft:</label>
    <label class="numberLabel" name="faultyStations">{{stationStatistic.faultyStations}}</label>
    <label>Stationen</label>
  </div>
  <div class="statistic-item">
    <label>Tagesaktuelle Fehlerquote:</label>
    <label class="numberLabel" name="dailyErrorRate">{{decimalToPercent(stationStatistic.dailyErrorRate)}}</label>
    <label>{{actualDate}}</label>
  </div>
  <div class="statistic-item" style="max-width: 14rem; margin-left:1rem">
    <label>Durchschnittliche Bearbeitungszeit einer Station (Von Problem bis gelöst)</label>
    <label class="numberLabel" name="averageServiceTimeOfStation">{{minutesToString(stationStatistic.averageServiceTimeOfStation)}}</label>
  </div>
</div>
