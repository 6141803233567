export enum Role {
  stationstatistic_view,
  station_delete,
  status_hint_edit,
  sensor_edit,
  sensor_delete,
  sensor_view,
  geo_edit,
  geo_view,
  regionaloffice_edit,
  regionaloffice_view,
  operator_edit,
  operator_view,
  image_delete,
  image_view,
  problem_edit,
  image_edit,
  notice_edit,
  notice_view,
  notice_delete,
  comment_edit,
  comment_view,
  comment_delete,
  regionaloffice_data_view,
  bd_nl_import,
  information_status_edit
}
