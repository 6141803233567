<div class="explanation-card">
  <div class="row">
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Legende
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table aria-label="explanation" class="mat-table">
        <tr>
          <th ></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Station</td>
          <td class="mat-cell" colspan="4">entspricht dem schwächsten Sensor</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Signal</td>
          <td class="mat-cell ok">100% - 83%</td>
          <td class="mat-cell error">82% - 25%</td>
          <td class="mat-cell offline">24% - 0%</td>
          <td class="mat-cell new">inaktiv</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Batterie</td>
          <td class="mat-cell ok">100%</td>
          <td class="mat-cell error">99% - 25%</td>
          <td class="mat-cell offline">24% - 0%</td>
          <td class="mat-cell new">keine Daten</td>
        </tr>
      </table>
    </mat-expansion-panel>
  </div>
</div>
