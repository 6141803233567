<div mat-dialog-content>
  <p>Station hinzufügen</p>
  <div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Station</mat-label>
        <input type="text"
               placeholder="Suchen"
               aria-label="Station"
               matInput
               [formControl]="control"
               [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="setStation($event)" autoActiveFirstOption
                          #auto="matAutocomplete">
          <mat-option *ngFor="let station of filteredOptions | async" [value]="station">
            {{station}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-between">
  <met-button (click)="onNoClick()" buttonText="Abbrechen"></met-button>
  <met-button (click)="onSave()" buttonText="Speichern"></met-button>
</div>
