import { Component, OnInit } from '@angular/core';
import { InformationStatus, StatusType } from '../../model/InformationStatus';
import { InformationStatusStoreService } from '../information-status-store.service';

@Component({
  selector: 'app-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent implements OnInit {
  activeInformationStatus: InformationStatus | undefined;
  isHidden: boolean | undefined;

  constructor(private informationStatusStoreService: InformationStatusStoreService) { }

  ngOnInit(): void {
    this.informationStatusStoreService.informationStatuses$.subscribe(statuses => {
      this.activeInformationStatus = statuses.find(s => s.isActive);
    });
    this.informationStatusStoreService.load();
  }

  isOkMessage(status: InformationStatus): boolean {
    return status.informationStatusType === StatusType.OK;
  }

  toggleBanner(): void {
    this.isHidden = !this.isHidden;
  }
}
