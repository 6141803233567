import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RegionalOffice } from '../model/RegionalOffice';

@Injectable({
  providedIn: 'root'
})
export class RegionalOfficeService {

  baseUrl = '/regionalOffice/'
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient) {
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file)
    return this.http.post(environment.API_URL + this.baseUrl, formData, {observe: 'response'})
  }

  getRegionalOffices(onlyActive: boolean) {
    return this.http.get<RegionalOffice[]>(environment.API_URL + this.baseUrl + "?onlyActive=" + onlyActive)
  }
}
