import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {SensorData, SensorService} from '../sensor.service';
import {map, Subject, takeUntil} from 'rxjs';
import {HumidityChart} from '../helpers/HumidityChart';
import {Chart} from 'angular-highcharts';
import {Options} from 'highcharts';


@Component({
  selector: 'met-humidity-chart',
  templateUrl: './humidity-chart.component.html',
  styleUrls: ['./humidity-chart.component.scss'],
})
export class HumidityChartComponent implements OnDestroy, OnChanges {
  constructor(private sensorService: SensorService) {}

  @Input()
  appId!: string;

  @Input()
  stationType!: string;

  @Input()
  date!: Date;

  @Input()
  backDays!: number;

  private unsubscribe$ = new Subject<void>();

  chartDataHumidity: any = [];
  chartDataLWET: any = [];

  humidityChart: Chart | undefined;

  now = new Date();

  convertDateTime(date: string) {
    return Date.parse(date);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getDataFromKachelmannApi();
  }

  private getDataFromKachelmannApi() {
    this.chartDataHumidity = [];
    this.chartDataLWET = [];

    this.humidityChart = new Chart(HumidityChart() as Options);
    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'HUMIDITY')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataHumidity.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.humidityChart?.addSeries(
            {
              type: 'spline',
              name: 'Relative Luftfeuchtigkeit',
              color: 'brown',
              data: this.chartDataHumidity,
            },
            true,
            false
          );
        })
      )
      .subscribe();

    if(this.stationType === 'METRILOG') {
    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'LEAF_WETNESS')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataLWET.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.humidityChart?.addSeries(
            {
              type: 'spline',
              name: 'Blattfeuchte',
              color: 'green',
              data: this.chartDataLWET,
            },
            true,
            false
          );
        })
      )
      .subscribe();
    }
    /**

    if (this.backDays > 1) {
      this.sensorService
        .getSensorData(this.appId, this.backDays, this.now, 'RH')
        .pipe(
          takeUntil(this.unsubscribe$),
          map((kachelmannObject: any) => {
            kachelmannObject[0].data.forEach(
              (item: any) => this.chartData.push([this.convertDateTime(item.timestamp), Number(item.RH)]),
              console.log(this.chartData),
              (this.humidityChart = new Chart(HumidityChart(this.chartData) as Options))
            );
          })
        )
        .subscribe();
    }
 */
  }
}
