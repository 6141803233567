import {Component, Input} from '@angular/core';
import {isOperatorEmtpy, Operator} from '../model/Operator';

@Component({
  selector: 'met-operator-card',
  templateUrl: './operator-card.component.html',
  styleUrls: ['./operator-card.component.scss']
})
export class OperatorCardComponent{

  constructor() { }

  @Input()
  operator : Operator =
    {
      operatorId: 0,
      vhOperatorId: "",
      firstName: "",
      lastName: "",
      street: "",
      city: "",
      postCode: "",
      phoneNumber: "",
      email: "",
      stationMap: new Map<string,string>()
    };

  emptyOperatorCheck(operator: Operator) { //returns true if operator is empty
    return isOperatorEmtpy(operator);
  }

  openEmail() {
    return "mailto:" + this.operator.email
  }

  openEditOperator() {

  }
}
