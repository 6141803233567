import {Component, Input} from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'met-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  buttonText = '';
  @Input()
  icon = '';

  constructor() {}

  @Input() isDisabled = false;

  @Input() type: string = 'button';

  @Input() isRaised = true;

  @Input() isLoading = false
}
