<met-page-layout>
  <div>
    <h3>Betreiberübersicht</h3>
  </div>
  <div class="operators">
    <div class="control-line">
      <div class="search">
        <div>
          <met-text-input deleteButtonId="deleteButton" placeHolderText="ID oder Name"
                          inputName="search" (searchStringChange)="searchOperators($event)"></met-text-input>
        </div>
        <div>
          <met-button [buttonText]="'Suchen'" (click)="searchOperators(searchString)" autofocus></met-button>
        </div>
      </div>
      <div>
        <met-button buttonText="Neuer Betreiber" routerLink="/operator-view"></met-button>
      </div>
    </div>
    <table mat-table aria-label="Operators" [dataSource]="operators">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let operator" class="clickableId"
            (click)="openOperatorDetailView(operator.operatorId)">
          {{operator.vhOperatorId}}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.lastName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>Vorname</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.firstName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="street">
        <th mat-header-cell *matHeaderCellDef>Straße+Hnr.</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.street}} {{operator.houseNumber}}
        </td>
      </ng-container>
      <ng-container matColumnDef="postcode">
        <th mat-header-cell *matHeaderCellDef>PLZ</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.postCode}}
        </td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>Ort</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.city}}
        </td>
      </ng-container>
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Telefon</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.phoneNumber}}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>E-Mail</th>
        <td mat-cell *matCellDef="let operator">
          {{operator.email}}
        </td>
      </ng-container>
      <ng-container matColumnDef="stations">
        <th mat-header-cell *matHeaderCellDef>Stationen</th>
        <td mat-cell *matCellDef="let operator">
          <div *ngFor="let station of operator.stationMap | keyvalue">
            <div (click)="openStationView(station.key)" class="clickableId">
              {{station.key}}
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplayOperator"></tr>
      <tr mat-row *matRowDef="let operators; columns: columnsToDisplayOperator"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="pageSizes" [pageSize]="currentPageSize"
                   [pageIndex]="pageNo" (page)="updatePageValues($event)"></mat-paginator>
  </div>
</met-page-layout>
