import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AUTH_GUARD} from './auth/auth-config';
import {RegionalOfficeImportComponent} from './regional-office-import/regional-office-import.component';
import {StationViewComponent} from './station-view/station-view.component';
import {StationDashboardComponent} from './station-dashboard/station-dashboard.component';
import {DiagramsComponent} from './diagrams/diagrams.component';
import {OperatorsComponent} from './operators/operators.component';
import {OperatorViewComponent} from './operator-view/operator-view.component';
import {InformationStatusCreationComponent} from './information/information-status-creation/information-status-creation.component';
import {Role} from './model/Role';

const routes: Routes = [
  {path: '', redirectTo: '/stations', pathMatch: 'full'},
  {path: 'stations', component: StationDashboardComponent, canActivate: [AUTH_GUARD]},
  {path: 'diagrams/:stationId', component: DiagramsComponent, canActivate: [AUTH_GUARD]},
  {path: 'operators', component: OperatorsComponent, canActivate: [AUTH_GUARD]},
  {path: 'regional-office-import', component: RegionalOfficeImportComponent, canActivate: [AUTH_GUARD]},
  {path: 'station-view/:stationId', component: StationViewComponent, canActivate: [AUTH_GUARD]},
  {path: 'operator-view/:operatorId', component: OperatorViewComponent, canActivate: [AUTH_GUARD]},
  {path: 'operator-view/:operatorId/:stationId/:stationName', component: OperatorViewComponent, canActivate: [AUTH_GUARD]},
  {path: 'operator-view', component: OperatorViewComponent, canActivate: [AUTH_GUARD]},
  {path: 'information', component: InformationStatusCreationComponent, canActivate: [AUTH_GUARD],
   data: {
    expectedRole: Role.information_status_edit
  }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
