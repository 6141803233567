import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakEventType, KeycloakService} from "keycloak-angular";
import {UserRoleProviderService} from "./user-role-provider-service";

@Injectable({
  providedIn: 'root'
})
export class MeteosolAuthGuard extends KeycloakAuthGuard {
  //TODO es fehlt einen Test für die Rollen
  constructor(
    protected myRouter: Router,
    protected keycloak: KeycloakService,
    private userProviderService: UserRoleProviderService
  ) {
    keycloak.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloakAngular.updateToken().then(success => {
            if(!success) {
              this.keycloakAngular.login({redirectUri: window.location.origin})
            }
          })
        }
      }
    })
    super(myRouter, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + state.url
      });
    }
    this.userProviderService.setUserRoles(this.roles);

    const expectedRole = route.data['expectedRole'];
    if (expectedRole) {
      return this.userProviderService.hasRequiredRole(expectedRole);
    } else {
      return this.authenticated;
    }

  }

}
