import {Component, OnInit} from '@angular/core';
import {OperatorsService} from './operators.service';
import {Operator} from '../model/Operator';
import {PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';

@Component({
  selector: 'met-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {

  operators: Operator[] = [];

  pageNo = 0;
  currentPageSize: number = 50;
  pageSizes: number[] = [25, 50, 100, 200];
  searchString: string = "";
  columnsToDisplayOperator: string[] = ['id', 'lastName', 'firstName', 'street', 'postcode', 'city', 'phoneNumber', 'email', 'stations'];
  length: number = 0;

  constructor(private operatorService: OperatorsService, private router: Router) {
  }

  ngOnInit(): void {
    this.updatePage()
    this.updateCountOperators()
  }

  updatePage() {
    this.operatorService.getAllOperators(this.pageNo, this.currentPageSize, this.searchString).pipe().subscribe(
      operators => {
        this.operators = operators;
      }
    )
  }

  updateCountOperators() {
    this.operatorService.countOperators(this.searchString).pipe().subscribe(
      length => {
        this.length = length
      }
    )
  }

  updatePageValues($event: PageEvent) {
    this.pageNo = $event.pageIndex
    this.currentPageSize = $event.pageSize
    this.updatePage()
  }

  searchOperators($event: string) {
    this.pageNo = 0;
    this.searchString = $event;
    this.updatePage();
    this.updateCountOperators()
  }

  openOperatorDetailView(operatorId: string) {
    this.router.navigate(["operator-view", operatorId]);
  }

  openStationView(key: unknown) {
    this.router.navigate(["station-view",key])
  }
}
