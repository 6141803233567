<div class="fileInput" mat-dialog-content>
  <p>Station {{stationId}} bearbeiten</p>
  <div class="edit-fields">
    <div class="station-property">
      <mat-form-field appearance="fill">
        <mat-label>BD/NL</mat-label>
        <input type="text"
               placeholder="Suchen"
               aria-label="BD/NL"
               matInput
               [formControl]="control"
               [matAutocomplete]="auto"
               [(ngModel)]="regionalOfficeName">
        <mat-autocomplete (optionSelected)="setRegionalOfficeName($event)" autoActiveFirstOption
                          #auto="matAutocomplete">
          <mat-option *ngFor="let regionalOffice of filteredOptions | async" [value]="regionalOffice">
            {{regionalOffice}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="station-property">
      <mat-form-field appearance="fill">
        <mat-label>Ansprechpartner</mat-label>
        <input matInput [(ngModel)]="contactPerson">
      </mat-form-field>
    </div>
    <div class="station-property">
      <mat-form-field appearance="fill">
        <mat-label>Gateway-Pin</mat-label>
        <input matInput [(ngModel)]="gateWayPin">
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-between">
  <met-button (click)="onNoClick()" buttonText="Abbrechen"></met-button>
  <met-button (click)="onSave()" buttonText="Speichern"></met-button>
</div>
