import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';

type SENSOR =
  | 'HUMIDITY'
  | 'LEAF_WETNESS'
  | 'RAIN'
  | 'TEMPERATURE'
  | 'DEWPOINT'
  | 'TMAX'
  | 'TMIN'
  | 'T_5CM'
  | 'WINDSPEED'
  | 'WINDGUST'
  | 'WIND_DIR'
  | 'PRECIPITATION_HOURLY'
  | 'PRECIPITATION_24h'
  | 'PRECIPITATION_1h';

const SENSOR_ID_MAPPING = {
  HUMIDITY: {
    backdaysId: 'RH_mean',
    backhoursId: 'RH',
  },
  LEAF_WETNESS: {
    backdaysId: 'LWET_mean',
    backhoursId: 'EXT_LWET',
  },
  RAIN: {
    backdaysId: 'RR_mean',
    backhoursId: 'RR',
  },
  TEMPERATURE: {
    backdaysId: 'Temp_mean',
    backhoursId: 'TL',
  },
  DEWPOINT: {
    backdaysId: 'TD_mean',
    backhoursId: 'TD',
  },
  TMAX: {
    backdaysId: 'Tmax',
    backhoursId: 'TX1',
  },
  TMIN: {
    backdaysId: 'Tmin',
    backhoursId: 'TN1',
  },
  T_5CM: {
    backdaysId: 'Tmin_Ground',
    backhoursId: 'T5',
  },
  WINDSPEED: {
    backdaysId: 'WSmean_max',
    backhoursId: 'EXT_FF_MS',
  },
  WINDGUST: {
    backdaysId: 'WSmax',
    backhoursId: 'EXT_FG_MS',
  },
  WIND_DIR: {
    backdaysId: 'WD_mean',
    backhoursId: 'DIR',
  },
  PRECIPITATION_HOURLY: {
    backdaysId: 'RR_sum',
    backhoursId: 'RR10m',
  },
  PRECIPITATION_24h: {
    backdaysId: 'RR_sum',
    backhoursId: '',
  },
  PRECIPITATION_1h: {
    backdaysId: '',
    backhoursId: 'RR10m',
  },
};
export interface SensorData {
  timestamp: number;
  value: number;
}

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  constructor(private http: HttpClient) {}

  getMonth2Digits(date: Date) {
    // 👇️ Add 1, because getMonth is 0-11
    const month = date.getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    }
    return month;
  }

  getDay2Digits(date: Date) {
    const day = date.getDate();
    if (day < 10) {
      return '0' + day;
    }
    return day;
  }

  getHours2Digits(date: Date) {
    const hours = date.getHours();
    if (hours < 10) {
      return '0' + hours;
    }
    return hours;
  }

  getMinutes2Digits(date: Date) {
    const minutes = date.getMinutes();
    if (minutes < 10) {
      return '0' + minutes;
    }
    return minutes;
  }

  getByBackHours(appId: string, hours: number, date: Date, sensorId: string): Observable<SensorData[]> {
    return this.http
      .get<any[]>(
        environment.API_URL +
          '/v01/timeseriesparam/meteosol/backhours/' +
          hours +
          '/stationids/' +
          appId +
          '/endtime/' +
          date.getFullYear() +
          '/' +
          this.getMonth2Digits(date) +
          '/' +
          this.getDay2Digits(date) +
          '/' +
          this.getHours2Digits(date) +
          this.getMinutes2Digits(date) +
          '/paramids/' +
          sensorId
      )
      .pipe(
        map((allData: any) => {
          return allData[0].data;
        }),
        map((sensorData: any[]) => {
          const newList: SensorData[] = [];
          sensorData.forEach((sensorDataPoint) => {
            newList.push({
              timestamp: sensorDataPoint.timestamp,
              value: sensorDataPoint[sensorId],
            });
          });
          return newList;
        })
      );
  }

  getByBackDays(appId: string, days: number, date: Date, sensorId: string): Observable<SensorData[]> {
    return this.http
      .get<any[]>(
        environment.API_URL +
          '/v01/timeseriesparam/meteosolclim/backdays/' +
          days +
          '/stationids/' +
          appId +
          '/endtime/' +
          date.getFullYear() +
          '/' +
          this.getMonth2Digits(date) +
          '/' +
          this.getDay2Digits(date) +
          '/' +
          this.getHours2Digits(date) +
          this.getMinutes2Digits(date)
      )
      .pipe(
        map((allData: any) => {
          return allData[0].data;
        }),
        map((sensorData: any[]) => {
          const newList: SensorData[] = [];
          sensorData.forEach((sensorDataPoint) => {
            newList.push({
              timestamp: sensorDataPoint.timestamp,
              value: sensorDataPoint[sensorId],
            });
          });
          return newList;
        })
      );
  }

  getSensorData(appId: string, backDays: number, endTime: Date, sensorId: SENSOR): Observable<SensorData[]> {
    if (backDays === 1 ) {
      return this.getByBackHours(appId, 24, endTime, SENSOR_ID_MAPPING[sensorId].backhoursId);
    } else if (backDays === 30) {
      return this.getByBackHours(appId, 720, endTime, SENSOR_ID_MAPPING[sensorId].backhoursId);
    } else  {
      return this.getByBackDays(appId, backDays, endTime, SENSOR_ID_MAPPING[sensorId].backdaysId);
    }
  }

  getSensorDataForPrecipitation(appId: string, backDays: number, endTime: Date, sensorId: SENSOR): Observable<SensorData[]> {
    if (backDays === 1 && sensorId ==='PRECIPITATION_1h') {
      return this.getByBackHours(appId, 24, endTime, SENSOR_ID_MAPPING[sensorId].backhoursId);
    } else if (backDays === 30 && sensorId ==='PRECIPITATION_1h') {
      return this.getByBackHours(appId, 720, endTime, SENSOR_ID_MAPPING[sensorId].backhoursId);
    } else if (backDays > 30 && sensorId ==='PRECIPITATION_24h') {
      return this.getByBackDays(appId, backDays, endTime, SENSOR_ID_MAPPING[sensorId].backdaysId);
    }
    else return of([])
  }
}
