<section (click)="$event.stopPropagation()" ngDefaultControl class="section">
  <div *ngIf="title" class="section-header">
    <div class="title-wrapper">{{ title }}</div>
  </div>
  <div [ngClass]="valueList.includes('25%-0%')? 'transmission-options' : 'section-options'">
    <mat-checkbox *ngFor="let value of valueList" (change)="checkValue(value)" value="{{ value }}"
                  #checkboxes [checked]="checkCheckedState(value)">
      <div [ngClass]="checkTransmissionSignal(value)">
        <div>
          {{ value }}
        </div>
      </div>
    </mat-checkbox>
  </div>
</section>
