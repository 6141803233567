export interface Operator {
  operatorId: number;
  vhOperatorId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  street: string;
  postCode: string;
  city: string;
  stationMap: Map<string, string>;
}

export function isOperatorEmtpy(operator: Operator) {
  return !(operator.firstName || operator.lastName || operator.street || operator.lastName || operator.lastName
    || operator.lastName || operator.lastName || operator.lastName || operator.operatorId > 0);
}
