import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Options} from 'highcharts';
import {map, Subject, takeUntil} from 'rxjs';
import {TemperatureChart} from '../helpers/TemperatureChart';
import {SensorData, SensorService} from '../sensor.service';

@Component({
  selector: 'met-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
})
export class TemperatureChartComponent implements OnDestroy, OnChanges {
  constructor(private sensorService: SensorService) {}
  @Input()
  appId!: string;

  @Input()
  date!: Date;

  @Input()
  backDays!: number;

  private unsubscribe$ = new Subject<void>();

  chartDataTemperature: any = [];

  chartDataDewpoint: any = [];

  chartDataTMax: any = [];

  chartDataTMin: any = [];

  chartDataT5cm: any = [];

  temperatureChart: Chart | undefined;

  now = new Date();

  convertDateTime(date: string) {
    return Date.parse(date);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getDataFromKachelmannApi();
  }

  private getDataFromKachelmannApi() {
    this.chartDataTemperature = [];
    this.chartDataDewpoint = [];
    this.chartDataTMax = [];
    this.chartDataTMin = [];
    this.chartDataT5cm = [];

    this.temperatureChart = new Chart(TemperatureChart() as Options);
    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'TEMPERATURE')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataTemperature.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.temperatureChart?.addSeries(
            {
              type: 'spline',
              name: 'Temperatur',
              color: 'brown',
              data: this.chartDataTemperature,
            },
            true,
            false
          );
        })
      )
      .subscribe();

    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'DEWPOINT')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataDewpoint.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.temperatureChart?.addSeries(
            {
              type: 'spline',
              name: 'Taupunkt',
              color: 'red',
              data: this.chartDataDewpoint,
            },
            true,
            false
          );
        })
      )
      .subscribe();

    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'TMAX')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataTMax.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.temperatureChart?.addSeries(
            {
              type: 'spline',
              name: 'T_max',
              color: 'blue',
              visible: false,
              data: this.chartDataTMax},
            true,
            false
          );
        })
      )
      .subscribe();

    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'TMIN')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataTMin.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.temperatureChart?.addSeries(
            {
              type: 'spline',
              name: 'T_min',
              color: 'black',
              visible: false,
              data: this.chartDataTMin},
            true,
            false
          );
        })
      )
      .subscribe();

    this.sensorService
      .getSensorData(this.appId, this.backDays, this.now, 'T_5CM')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((kachelmannObject: SensorData[]) => {
          kachelmannObject.forEach((item: any) =>
            this.chartDataT5cm.push([this.convertDateTime(item.timestamp), Number(item.value)])
          );
          this.temperatureChart?.addSeries(
            {
              type: 'spline',
              name: 'Temperatur 5cm',
              color: 'green',
              data: this.chartDataT5cm},
            true,
            false
          );
        })
      )
      .subscribe();
  }
}
