<div>
  <div class="error">
    {{errorMessage}}
  </div>
  <div *ngIf="(imageUrls$ | async) as imageUrls">
    <div class="gallery">
      <div *ngFor="let imageurl of imageUrls">
        <div class="gallery-item" (click)="displayImageFullscreen(imageurl.url)">
          <img [src]="imageurl.thumbnailUrl"/>
          <button *ngIf="hasRequiredRole(role.image_delete)" class="gallery-button" mat-icon-button (click)="openConfirmationDialogForDeletion(imageurl); $event.stopPropagation()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <input #fileUpload (change)="selectFiles($event)" class="file-input" type="file" multiple>
  <div class="file-upload">
    <met-button *ngIf="hasRequiredRole(role.image_edit)" [buttonText]="'Bilder hochladen'" (click)="fileUpload.click()"></met-button>
  </div>
</div>
