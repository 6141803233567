<div class="table-container">
  <table aria-label="Stationen" mat-table [dataSource]="stations">
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
        <mat-cell class="status-cell" [class.sensorHeadline]="station.sensors.length===0">
          <mat-icon class="status" [ngClass]="station.stationStatus">circle</mat-icon>
        </mat-cell>
      </td>
    </ng-container>
    <ng-container matColumnDef="stationId">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
        <mat-cell [class.sensorHeadline]="station.sensors.length===0" (click)="openStationDetails(station.stationId, storedCurrentPageSize, pageNo)">
          <div [id]="station.stationId" class="clickableId">{{station.stationId}}</div>
        </mat-cell>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
        <mat-cell [class.sensorHeadline]="station.sensors.length===0">
          {{station.name}}
        </mat-cell>
      </td>
    </ng-container>
    <ng-container *ngIf="hasRequiredRole(role.regionaloffice_view)">
      <ng-container matColumnDef="regionalOffice">
        <th mat-header-cell *matHeaderCellDef>BD/NL</th>
        <td mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
          <mat-cell [class.sensorHeadline]="station.sensors.length===0">
            {{station.regionalOfficeRepresentation.name}}
          </mat-cell>
        </td>
      </ng-container>
      <ng-container matColumnDef="contactPerson">
        <th mat-header-cell *matHeaderCellDef>Ansprechpartner</th>
        <td mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
          <mat-cell [class.sensorHeadline]="station.sensors.length===0">
            {{station.contactPerson}}
          </mat-cell>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="hasRequiredRole(role.sensor_view)">
      <ng-container matColumnDef="sensorId">
        <th mat-header-cell *matHeaderCellDef>SensorId</th>
        <td class="sensors" mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
          <mat-cell [class.sensorHeadline]="station.sensors.length===0"></mat-cell>
          <table mat-table aria-label="sensor" [dataSource]="station.sensors">
            <ng-container matColumnDef="sensorGroup">
              <th *matHeaderCellDef></th>
              <td #td class="innerMatCell" mat-cell *matCellDef="let sensor;"
                  [attr.rowSpan]="this.calcRowSpan(station,sensor)"
                  [style.display]="sensor.sensorId !== '' ? '' : 'none'"
                  [ngClass]="checkIfItIsLastSensorRow(sensor,station) ?
                    'no_border' : ''">
                <mat-cell [ngClass]="td.rowSpan === 2 ? 'connected' :
                  'not_connected'">
                  {{sensor.sensorId}}
                </mat-cell>
              </td>
            </ng-container>
            <tr class="innerRow" mat-row *matRowDef="let stations; columns: columnsToDisplaySensorGroups"></tr>
          </table>
        </td>
      </ng-container>
      <ng-container matColumnDef="battery">
        <th mat-header-cell *matHeaderCellDef>Batterie</th>
        <td class="innerTabelCell" mat-cell *matCellDef="let station;"
            [class.verticalAlignNoSenor]="station.sensors.length===0">
          <mat-cell [class.sensorHeadline]="station.sensors.length===0"></mat-cell>
          <ng-container [style.display]="station.sensors.length>0 ? '' : 'none'" *ngFor="let sensor of station.sensors">
            <mat-cell [ngClass]="sensor.batteryState">{{sensor.validBatteryCapacity}}
              / {{sensor.maximumBatteryCapacity}}</mat-cell>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="sensorType">
        <th mat-header-cell *matHeaderCellDef>Parameter</th>
        <td class="sensors" mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
          <mat-cell [class.sensorHeadline]="station.sensors.length===0"></mat-cell>
          <ng-container [style.display]="station.sensors.length>0 ? '' : 'none'" *ngFor="let sensor of station.sensors">
            <mat-cell>{{sensor.sensorShortCut | sensorPipe}}</mat-cell>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="sensorTransmissionSignal">
        <th mat-header-cell *matHeaderCellDef>Übertragungssignal</th>
        <td class="sensors" mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
          <div>
            <mat-cell [class.sensorHeadline]="station.sensors.length===0"></mat-cell>
            <ng-container [style.display]="station.sensors.length>0 ? '' : 'none'" *ngFor="let sensor of station.sensors">
              <mat-cell [ngClass]="sensor.transmissionSignal"></mat-cell>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="statusComment">
        <th mat-header-cell *matHeaderCellDef>Status-Hinweis</th>
        <td class="sensors" mat-cell *matCellDef="let station" [class.verticalAlignNoSenor]="station.sensors.length===0">
          <mat-cell [class.sensorHeadline]="station.sensors.length===0"></mat-cell>
          <ng-container *ngFor="let sensor of station.sensors">
            <mat-cell class="statusComment">
              <mat-select [disabled]="!hasRequiredRole(role.status_hint_edit)" [value]="sensor.sensorStatus.statusComment | getStatusCommentValue"
                  (selectionChange)="setStatusComment($event.value, station.stationId, sensor)">
                <mat-option *ngFor="let comment of statusComments" [value]="comment">
                  {{ comment }}
                </mat-option>
              </mat-select>
              <div class="statusCommentDate">
                {{ sensor.sensorStatus.startDate | parseDate }}
              </div>
            </mat-cell>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="hasRequiredRole(role.notice_view)" matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef>Notiz</th>
      <td class="sensors" mat-cell *matCellDef="let station">
        <mat-cell>
          <div *ngIf="station.note; else emptyPageIcon" class="existingRemark">
            <img (click)="openDialogNoteStation(station, hasRequiredRole(role.notice_edit))"
              [id]="'station-note-' + station.stationId"
              src="assets/icons8Note.png" alt="Paper Note"
              style="width: 2em"
              [matTooltip]="station.note.text">
            <ng-container *ngIf="hasRequiredRole(role.notice_delete)">
              <img (click)="archiveRemarkNoteOnStation(station)"
                src="assets/icons8-trash-96.png" alt="Trash/Delete"
                style="width: 2em; margin-left: 0.2em"
                [matTooltip]="'Notiz löschen'">
            </ng-container>
          </div>
          <ng-template #emptyPageIcon>
            <ng-container *ngIf="hasRequiredRole(role.notice_edit)">
              <img (click)="createNoteOnStation(station)"
                [id]="'station-note-' + station.stationId"
                src="assets/icons8NoteEmpty.png" alt="Paper Note"
                style="width: 2em"
                [matTooltip]="'Notiz erstellen'">
            </ng-container>
          </ng-template>
        </mat-cell>
        <ng-container *ngIf="hasRequiredRole(role.sensor_view)">
          <ng-container *ngFor="let sensor of station.sensors">
            <mat-cell>
              <div *ngIf="sensor.note; else emptyPageIcon" class="existingRemark">
                <img (click)="openDialogNoteSensor(sensor, station.stationId, hasRequiredRole(role.notice_edit))"
                  [id]="'sensor-note-' + sensor.sensorType"
                  src="assets/icons8Note.png" alt="Paper Note"
                  style="width: 2em"
                  [matTooltip]="sensor.note.text">
                <ng-container *ngIf="hasRequiredRole(role.notice_delete)">
                  <img (click)="archiveRemarkNoteOnSensor(sensor)"
                  src="assets/icons8-trash-96.png" alt="Trash/Delete"
                  style="width: 2em; margin-left: 0.2em"
                  [matTooltip]="'Notiz löschen'">
                </ng-container>
              </div>
              <ng-template #emptyPageIcon>
                <ng-container *ngIf="hasRequiredRole(role.notice_edit)">
                  <img (click)="createNoteOnSensor(sensor, station.stationId)"
                    [id]="'sensor-note-' + sensor.sensorType"
                    src="assets/icons8NoteEmpty.png" alt="Paper Note"
                    style="width: 2em"
                    [matTooltip]="'Notiz erstellen'">
                </ng-container>
              </ng-template>
            </mat-cell>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
    <ng-container *ngIf="hasRequiredRole(role.comment_view)" matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Kommentar</th>
      <td class="sensors" mat-cell *matCellDef="let station">
        <mat-cell>
          <div *ngIf="station.comment; else emptyPageIcon" class="existingRemark">
            <img (click)="openDialogCommentStation(station, hasRequiredRole(role.comment_edit))"
                 [id]="'station-comment-' + station.stationId"
                 src="assets/icons8Note.png" alt="Paper Note"
                 style="width: 2em"
                 [matTooltip]="station.comment.text">
            <ng-container *ngIf="hasRequiredRole(role.comment_delete)">
              <img (click)="archiveRemarkCommentStation(station)"
                src="assets/icons8-trash-96.png" alt="Trash/Delete"
                style="width: 2em; margin-left: 0.2em"
                [matTooltip]="'Kommentar löschen'">
            </ng-container>
          </div>
          <ng-template #emptyPageIcon>
            <ng-container *ngIf="hasRequiredRole(role.comment_edit)">
              <img (click)="openDialogCommentStation(station)"
                [id]="'station-note-' + station.stationId"
                src="assets/icons8NoteEmpty.png" alt="Paper Note"
                style="width: 2em"
                [matTooltip]="'Kommentar erstellen'">
            </ng-container>
          </ng-template>
        </mat-cell>
        <ng-container *ngIf="hasRequiredRole(role.sensor_view)">
          <ng-container *ngFor="let sensor of station.sensors">
            <mat-cell>
              <div *ngIf="sensor.comment; else emptyPageIcon">
                <img (click)="openDialogCommentSensor(sensor, station.stationId, hasRequiredRole(role.comment_edit))"
                  [id]="'sensor-comment-' + sensor.sensorType"
                  src="assets/icons8Note.png" alt="Paper Comment"
                  style="width: 2em"
                  [matTooltip]="sensor.comment.text">
                <ng-container *ngIf="hasRequiredRole(role.comment_delete)">
                  <img (click)="archiveRemarkCommentSensor(sensor)"
                    src="assets/icons8-trash-96.png" alt="Trash/Delete"
                    style="width: 2em; margin-left: 0.2em"
                    [matTooltip]="'Kommentar löschen'">
                </ng-container>
              </div>
              <ng-template #emptyPageIcon>
                <ng-container *ngIf="hasRequiredRole(role.comment_edit)">
                  <img (click)="openDialogCommentSensor(sensor, station.stationId)"
                    [id]="'sensor-comment-' + sensor.sensorType"
                    src="assets/icons8NoteEmpty.png" alt="Paper Comment"
                    style="width: 2em"
                    [matTooltip]="'Kommentar erstellen'">
                </ng-container>
              </ng-template>
            </mat-cell>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
    <ng-container *ngIf="hasRequiredRole(role.image_view)" matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef>Bilder</th>
      <td class="sensors" mat-cell *matCellDef="let station">
        <mat-cell [id]="'render-test'">
          <div *ngIf="station.imageCount>0">
            <img [id]="'station-images-' + station.stationId"
                 src="assets/icons8imagefile.png" alt="Images"
                 style="width: 2em"
                 [matTooltip]="station.imageCount + (station.imageCount > 1 ? 'Bilder' : ' Bild')">
          </div>
        </mat-cell>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayStation; sticky: true"></tr>
    <tr mat-row *matRowDef="let stations; columns: columnsToDisplayStation"></tr>
  </table>
  <mat-paginator [length]="length" [pageSizeOptions]="pageSizes" [pageSize]="currentPageSize"
                 [pageIndex]="pageNo"
                 (page)="onPageSizeChange($event)"></mat-paginator>
</div>
