import * as FilterStateActions from './filter-state.actions';
import {StatusComment} from '../station-view/StatusComment';
import {Action, createReducer, on} from '@ngrx/store';
import {saveCurrentPageSize} from "./filter-state.actions";

export const filterStateFeatureKey = 'filterState';

export interface AppState {
  [filterStateFeatureKey]: FilterState;
}

export interface FilterState {
  regionalOffices: string[];
  statusComments: StatusComment[];
  transmissionSignals: string[];
  startDate: Date | undefined;
  endDate: Date | undefined;
  filterForBatteryStatus: string[];
  filterForNoOperator: string[];
  currentPageNumber: number;
  elementToScroll: string;
  currentPageSize: number| undefined;
  searchString: string;
}

export const initialState: FilterState = {
  regionalOffices: [],
  statusComments: [],
  transmissionSignals: [],
  startDate: undefined,
  endDate: undefined,
  filterForBatteryStatus: [],
  filterForNoOperator: [],
  currentPageNumber: 0,
  elementToScroll: "",
  currentPageSize: undefined,
  searchString: ""
}


export const filterReducer = createReducer(
  initialState,

  on(FilterStateActions.saveRegionalOffices, (state: FilterState, {regionalOffices}) => ({
    ...state, regionalOffices: regionalOffices
  })),
  on(FilterStateActions.saveBatteryState, (state: FilterState, {filterForBatteryState}) => ({
    ...state, filterForBatteryStatus: filterForBatteryState
  })),
  on(FilterStateActions.saveStatusComments, (state: FilterState, {statusComments}) => ({
    ...state, statusComments: statusComments
  })),
  on(FilterStateActions.saveTransmissionSignal, (state: FilterState, {transmissionSignals}) => ({
    ...state, transmissionSignals: transmissionSignals
  })),
  on(FilterStateActions.saveStartDate, (state: FilterState, {startDate}) => ({
    ...state, startDate: startDate
  })),
  on(FilterStateActions.saveEndDate, (state: FilterState, {endDate}) => ({
    ...state, endDate: endDate
  })),
  on(FilterStateActions.saveNoOperator, (state: FilterState, {filterForNoOperator}) => ({
    ...state, filterForNoOperator: filterForNoOperator
  })),
  on(FilterStateActions.saveCurrentPageNo, (state: FilterState, {currentPageNumber}) => ({
    ...state, currentPageNumber: currentPageNumber
  })),
  on(FilterStateActions.saveElementToScroll, (state: FilterState, {elementToScroll}) => ({
    ...state, elementToScroll: elementToScroll
  })),
  on(FilterStateActions.saveCurrentPageSize, (state: FilterState, {currentPageSize}) => ({
    ...state, currentPageSize: currentPageSize
  })),
  on(FilterStateActions.saveSearchString, (state: FilterState, {searchString}) => ({
    ...state, searchString: searchString
  }))
);

export function reducer(state: FilterState | undefined, action: Action): any {
  return filterReducer(state, action);
}
