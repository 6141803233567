import { Component, OnDestroy, OnInit } from '@angular/core';
import {RegionalOfficeService} from './regional-office.service';
import {MatDialogRef} from '@angular/material/dialog';
import {HttpStatusCode} from '@angular/common/http';
import {Subject} from 'rxjs';

@Component({
  selector: 'met-regional-office-import',
  templateUrl: './regional-office-import.component.html',
  styleUrls: ['./regional-office-import.component.scss'],
})
export class RegionalOfficeImportComponent implements OnInit, OnDestroy {
  fileToUpload: File | null | undefined;
  result: string | undefined;
  process: string | undefined;
  fileName: string | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private regionalOfficeService: RegionalOfficeService,
    public dialogRef: MatDialogRef<RegionalOfficeImportComponent>
  ) {}

  ngOnInit(): void {
    this.fileName = 'Keine Datei ausgewählt';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  uploadFile() {
    if (this.fileToUpload == undefined) {
      this.process = 'Bitte wählen Sie zuerst eine CSV-Datei aus.';
    } else {
      this.process = this.fileToUpload?.name + ' wird hochgeladen...';
      this.regionalOfficeService.uploadFile(this.fileToUpload).subscribe((result) => {
        if (result.status == HttpStatusCode.Ok) {
          this.result = 'Importieren erfolgreich abgeschlossen.';
        } else if (result.status == HttpStatusCode.NoContent) {
          this.result = 'Datei erfolgreich gelesen. Es wurden keine neuen BD hinzugefügt.';
        } else {
          this.result = 'Es ist ein Fehler aufgetreten.';
        }
      });
    }
  }

  handleInput(fileList: FileList | null) {
    this.fileToUpload = fileList?.item(0);
    this.fileName = this.fileToUpload?.name;
    this.process = '';
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
