import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../model/Role';
import { UserRoleProviderService } from './user-role-provider-service';

@Injectable({
  providedIn: 'root',
})
export class DummyAuthGuard implements CanActivate {

  constructor(private userProviderService : UserRoleProviderService) {
    const allRoles = Object.values(Role).map(role => role.toString());
    this.userProviderService.setUserRoles(allRoles);
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
