import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromFilterState from './filter-state.reducer';

export const selectFilterStateState = createFeatureSelector<fromFilterState.FilterState>(
  fromFilterState.filterStateFeatureKey
);

export const selectRegionalOffices = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.regionalOffices
);

export const selectBatteryState = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.filterForBatteryStatus
);

export const selectNoOperator = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.filterForNoOperator
);

export const selectStatusComments = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.statusComments
);

export const selectTransmissionSignals = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.transmissionSignals
);

export const selectStartDate = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.startDate
);

export const selectEndDate = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.endDate
);

export const selectCurrentPageNumber = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.currentPageNumber
);

export const selectElementToScroll = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.elementToScroll
);

export const selectCurrentPageSize = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.currentPageSize
);

export const selectSearchString = createSelector(
  selectFilterStateState,
  (state: fromFilterState.FilterState) => state.searchString
);
