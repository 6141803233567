<met-page-layout>
  <div class="container">
    <div class="flex-container">
      <ng-container *ngIf="standardField">
        <met-button #standardButton (click)="activateTextfield(statusType.OK)" [isRaised]="getDisabledStatus(statusType.DISTURBANCE)" buttonText="Alles ok"></met-button>
      </ng-container>
      <ng-container *ngIf="disturbanceField">
        <met-button #disturbanceButton (click)="activateTextfield(statusType.DISTURBANCE)" [isRaised]="getDisabledStatus(statusType.OK)" buttonText="Technische Störung"></met-button>
      </ng-container>
    </div>
    <form #form="ngForm" (ngSubmit)="submit()">
      <div class="flex-container form-fields">
        <div class="field">
          <label>Standard Text</label>
          <textarea class="standard-field" #standardField [(ngModel)]="standardText" name="standard" matInput type="text"></textarea>
        </div>
        <div class="field">
          <label>Technische Störung</label>
          <textarea class="disturbance-field" #disturbanceField [(ngModel)]="disturbanceText" name="disturbance" matInput type="text"></textarea>
        </div>
      </div>
      <div class="flex-container action-buttons">
        <met-button (click)="cancel()" buttonText="Abbrechen"></met-button>
        <met-button type="submit" buttonText="Speichern"></met-button>
      </div>
    </form>
  </div>
</met-page-layout>