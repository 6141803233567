<div mat-dialog-content>
  <p>Kommentar erstellen</p>
  <mat-form-field appearance="fill">
    <mat-label>Kommentar</mat-label>
    <input matInput [(ngModel)]="data">
  </mat-form-field>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-between">
  <met-button (click)="onNoClick()" buttonText="Abbrechen"></met-button>
  <met-button [mat-dialog-close]="data" buttonText="Speichern"></met-button>
</div>
