export const environment = {
  production: true,
  API_URL: 'https://backend.meteomanager-staging.meteosol.de' + '/api',
  keycloak: {
    url: 'https://keycloak.meteomanager-staging.meteosol.de' + '/auth',
    realm: 'meteosol-admin-realm',
    clientId: 'meteosol-admin-client',
  },
  auth: {
    use_dummy_auth: false,
  },
};
