import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'met-edit-operator-field',
  templateUrl: './edit-operator-field.component.html',
  styleUrls: ['./edit-operator-field.component.scss']
})
export class EditOperatorFieldComponent {
  @Input()
  fieldName: string = '';

  @Input()
  operatorValue: string = '';

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  changeValue() {
    this.valueChange.emit(this.operatorValue)
  }
}
