import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sensorPipe'
})
export class SensorPipePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'TEMP':
        return 'Temperatur';
      case 'TL':
        return 'Temperatur';
      case 'temperature':
        return 'Temperatur / Luftfeuchte';
      case 'PRECIPITATION':
        return 'Niederschlag';
      case 'RR10':
        return 'Niederschlag';
      case 'rain':
        return 'Niederschlag';
      case 'GLOBAL_RADIATION':
        return 'Globalstrahlung';
      case 'GL10':
        return 'Globalstrahlung';
      case 'glrad':
        return 'Globalstrahlung';
      case 'wind':
        return 'Wind';
      case 'WIND_DIRECTION':
        return 'Windrichtung';
      case 'DIR':
        return 'Windrichtung';
      case 'WIND_VELOCITY':
        return 'Windgeschwindigkeit';
      case 'FF':
        return 'Windgeschwindigkeit';
      case 'LEAF_WETNESS':
        return 'Blattfeuchte';
      case 'LEAF_WETNESS_BA':
        return 'Blattfeuchte';
      case 'lwet':
        return 'Blattfeuchte';
      case 'lwetba':
        return 'Blattfeuchte';
      case 'EXT_LWET':
        return 'Blattfeuchte';
      case 'EXT_LWETBA':
        return 'Blattfeuchte';
      case 'AIR_PRESSURE':
        return 'Luftdruck';
      case 'press':
        return 'Luftdruck';
      case 'QFF':
        return 'Luftdruck';
      case 'DEWPOINT':
        return 'Luftfeuchte';
      case 'DEW_POINT':
        return 'Luftfeuchte';
      case 'TD':
        return 'Luftfeuchte';
      case 'TEMP5':
        return 'Temperatur 5cm';
      case 'T5':
        return 'Temperatur 5cm';
      case 't5cm':
        return 'Temperatur 5cm';
      case 'T-10':
        return 'Bodentemperatur -10 cm';
      case 'TEMP_MINUS10':
        return 'Bodentemperatur -10 cm';
      case 'soiltemp10':
        return 'Bodentemperatur -10 cm';
      case 'T-20':
        return 'Bodentemperatur -20 cm';
      case 'soiltemp20':
        return 'Bodentemperatur -20 cm';
      case 'TEMP_MINUS20':
        return 'Bodentemperatur -20 cm';
      case 'TEMP_MINUS80':
        return 'Bodentemperatur -80 cm';
      case 'soiltemp80':
        return 'Bodentemperatur -80 cm';
      case 'EXT_SOILTEMP80':
        return 'Bodentemperatur -80 cm';
      case 'TEMP_MINUS120':
        return 'Bodentemperatur -120 cm';
      case 'soiltemp120':
        return 'Bodentemperatur -120 cm';
      case 'EXT_SOILTEMP120':
        return 'Bodentemperatur -120 cm';
      case 'SOIL_HUMIDITY_MINUS20':
        return 'Bodenfeuchte -20 cm';
      case 'SOIL_HUMIDITY_MINUS80':
        return 'Bodenfeuchte -80 cm';
      case 'SOIL_HUMIDITY_MINUS120':
        return 'Bodenfeuchte -120 cm';
      case 'EXT_SOILHUM20':
        return 'Bodenfeuchte -20 cm';
      case 'soilhum20':
        return 'Bodenfeuchte -20 cm';
      case 'EXT_SOILHUM80':
        return 'Bodenfeuchte -80 cm';
      case 'soilhum80':
        return 'Bodenfeuchte -80 cm';
      case 'EXT_SOILHUM120':
        return 'Bodenfeuchte -120 cm';
      case 'soilhum120':
        return 'Bodenfeuchte -120 cm';
      case 'EXT_SOILHUMBA10':
        return 'Bodensaugspannung -10 cm';
      case 'soilhum10ba':
        return 'Bodensaugspannung -10 cm';
      case 'SOIL_HUMIDITY_MINUS10':
        return 'Bodensaugspannung -10 cm';
      default:
        return value;
    }
  }
}
