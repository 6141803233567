<button *ngIf="isRaised" mat-raised-button class="custom-button" [type]="type" [disabled]="isDisabled"
        [id]="buttonText + '-button'">
  <mat-icon *ngIf="icon !== ''">{{ icon }}</mat-icon>
  <span class="button-text" *ngIf="buttonText !== ''">{{ buttonText }}</span>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
</button>
<button *ngIf="!isRaised" mat-stroked-button class="custom-button" [type]="type" [disabled]="isDisabled"
        [id]="buttonText + '-button'">
  <mat-icon *ngIf="icon !== ''">{{ icon }}</mat-icon>
  <span class="button-text" *ngIf="buttonText !== ''">{{ buttonText }}</span>
  <mat-spinner *ngIf="isLoading" color="primary"></mat-spinner>
</button>
