import { Component, Input } from '@angular/core';
import { StationStatisticsRepresentation } from './StationStatisticsRepresentation';

@Component({
  selector: 'met-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent {
  @Input()
  stationStatistic: StationStatisticsRepresentation = {
    installedStations: 0,
    errorFreeStations: 0,
    inactiveStations: 0,
    faultyStations: 0,
    dailyErrorRate: 0,
    averageServiceTimeOfStation: 0
  };
  actualDate: string = new Date().toLocaleDateString('de-DE');

  minutesToString(minutes: number): string {
    if (minutes < 0) return "0d 0h 0min";
    let days = Math.floor(minutes / (60*24));
    minutes  -= days*60*24;
    let hrs   = Math.floor(minutes / 60);
    minutes  -= hrs*60;
    return days + "d " + hrs + "h " + minutes + "min";
  }

  private round(value: number, precision: number = 0) {
    const multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
  }

  decimalToPercent(num: number): string {
    return (this.round(num, 2) * 100).toFixed(0) + '%';
  }
}
