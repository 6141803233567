import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MatCheckbox} from '@angular/material/checkbox';

@Component({
  selector: 'met-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, OnDestroy {

  @Input()
  title: string = '';

  @Input()
  valueList: string[] = [];

  @Input()
  resetEvent: Observable<void> = new Observable<void>();

  @Output()
  valueChanged = new EventEmitter<any>();

  @ViewChildren("checkboxes") checkboxes: QueryList<MatCheckbox> = new QueryList<MatCheckbox>();

  @Input()
  checkedInputValues: Observable<string[]> = new Observable<string[]>()
  private checkedValues: Set<string> = new Set();
  private resetSubscription: Subscription | undefined;
  private inputValueSubscription$: Subscription | undefined;

  checkValue(value: string) {
    if (this.checkedValues.has(value) || this.checkCheckedState(value)) {
      this.checkedValues.delete(value);
    } else {
      this.checkedValues.add(value);
    }
    this.valueChanged.emit(Array.from(this.checkedValues));
  }

  ngOnInit() {
    this.resetSubscription = this.resetEvent.subscribe(() => {
      this.checkboxes.forEach(element => {
        element.checked = false
        this.checkedValues.clear()
      })
    });
    this.inputValueSubscription$ = this.checkedInputValues.subscribe(values => {
      values.forEach(string => this.checkedValues.add(string))
    });
  }

  ngOnDestroy() {
    this.resetSubscription?.unsubscribe();
    this.inputValueSubscription$?.unsubscribe()
  }

  checkTransmissionSignal(value: string) {
    if (value == '100%-83%') {
      return 'ok'
    } else if (value == '83%-25%') {
      return 'error'
    } else if (value == '25%-0%') {
      return 'offline'
    } else if (value == 'inaktiv') {
      return 'new'
    } else {
      return 'none'
    }
  }

  checkCheckedState(value: string) {
    return this.checkedValues.has(value)
  }
}

