import {Injectable} from "@angular/core";
import {User} from "../model/User";
import {Role} from '../model/Role';

@Injectable({providedIn: 'root'})
export class UserRoleProviderService {
  private user : User = {
    roles: []
  };

  public setUserRoles(roles: string[]): void {
    this.user.roles = roles;
  }

  public hasRequiredRole(requiredRole: Role): boolean {
    return this.user.roles.includes(Role[requiredRole]);
  }
}
