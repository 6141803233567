import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { UserRoleProviderService } from '../auth/user-role-provider-service';
import { Role } from '../model/Role';

@Component({
  selector: 'met-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  showMenu = false;
  activeItem: string | undefined;
  readonly role = Role;

  constructor(private keycloakService: KeycloakService, private userRoleProviderService: UserRoleProviderService) { }

  hasRequiredRole(requiredRole: Role): boolean {
    return this.userRoleProviderService.hasRequiredRole(requiredRole);
  }

  logout() {
    this.keycloakService.logout();
  }

  setActiveItem(page: string) {
    this.activeItem = page;
  }

  openSupport() {
    window.open('https://conciso.atlassian.net/servicedesk/customer/portal/11');
  }
}
