<met-page-layout>
  <div>
    <div class="title">
      <h3 routerLink="/stations" style="cursor: pointer; text-decoration: underline">Stationsübersicht</h3>
      <mat-icon class="mat-icon-header">keyboard_arrow_right</mat-icon>
      <h3>{{ stationId }}</h3>
    </div>
    <div>
      <a class="diagrams-link" [routerLink]="['/diagrams', stationId]">
        <h4>Diagramme</h4>
      </a>
    </div>
    <div>
      <div>
        <h4>Station</h4>
      </div>
      <table aria-label="station" mat-table [dataSource]="tableDatasource">
        <ng-container matColumnDef="stationStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let station">
            <mat-cell class="status-cell">
              <mat-icon class="status" [ngClass]="station.stationStatus">circle</mat-icon>
            </mat-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="stationId">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let station">
            <mat-cell>
              {{ station.stationId }}
            </mat-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="appId">
          <th mat-header-cell *matHeaderCellDef>App-Code</th>
          <td mat-cell *matCellDef="let station">
            <mat-cell>
              {{ station.appId }}
            </mat-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let station">
            <mat-cell>
              {{ station.name }}
            </mat-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>Inbetriebnahme</th>
          <td mat-cell *matCellDef="let station">
            <mat-cell>
              {{ station.creationDate }}
            </mat-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Stationstyp</th>
          <td mat-cell *matCellDef="let station">
            <mat-cell>
              {{ station.stationType }}
            </mat-cell>
          </td>
        </ng-container>
        <ng-container *ngIf="hasRequiredRole(role.regionaloffice_view)">
          <ng-container matColumnDef="regionalOffice">
            <th mat-header-cell *matHeaderCellDef>BD/NL</th>
            <td mat-cell *matCellDef="let station">
              <mat-cell>
                {{ station.regionalOfficeRepresentation.name }}
              </mat-cell>
            </td>
          </ng-container>
          <ng-container matColumnDef="contactPerson">
            <th mat-header-cell *matHeaderCellDef>Ansprechpartner</th>
            <td mat-cell *matCellDef="let station">
              <mat-cell>
                {{ station.contactPerson }}
              </mat-cell>
            </td>
          </ng-container>
          <ng-container matColumnDef="gateWayPin">
            <th mat-header-cell *matHeaderCellDef>Gateway-Pin</th>
            <td mat-cell *matCellDef="let station">
              <mat-cell>
                {{ station.gateWayPin }}
              </mat-cell>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let station; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="hasRequiredRole(role.regionaloffice_edit)" class="edit-button" data-test="edit-button">
        <met-button icon="edit" [buttonText]="'Bearbeiten'" (click)="openEditStation()"></met-button>
      </div>
    </div>
    <div>
      <ng-container *ngIf="hasRequiredRole(role.geo_view)">
        <div>
          <h4>Standort</h4>
        </div>
        <div class="locationData">
          <div class="locationTable">
            <table aria-label="location" mat-table [dataSource]="tableDatasource">
              <ng-container matColumnDef="longitude">
                <th mat-header-cell *matHeaderCellDef>lon</th>
                <td mat-cell *matCellDef="let station">
                  <mat-cell>
                    {{ station.longitude }}
                  </mat-cell>
                </td>
              </ng-container>
              <ng-container matColumnDef="latitude">
                <th mat-header-cell *matHeaderCellDef>lat</th>
                <td mat-cell *matCellDef="let station">
                  <mat-cell>
                    {{ station.latitude }}
                  </mat-cell>
                </td>
              </ng-container>
              <ng-container matColumnDef="altitude">
                <th mat-header-cell *matHeaderCellDef>alt(m)</th>
                <td mat-cell *matCellDef="let station">
                  <mat-cell>
                    {{ station.altitude }}
                  </mat-cell>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsLocation"></tr>
              <tr mat-row *matRowDef="let station; columns: displayedColumnsLocation"></tr>
            </table>
          </div>
          <div class="map-container">
            <div class="map-frame">
              <div *ngIf="isGeoLocationDefined" id="map"></div>
              <div class="location-error" *ngIf="!isGeoLocationDefined">
                Auf Grund fehlender Geokoordinaten kann die Karte nicht angezeigt werden!
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hasRequiredRole(role.operator_view)">
        <div>
          <met-operator-card [operator]="operator"></met-operator-card>
        </div>
        <div class="edit-operator" *ngIf="hasRequiredRole(role.operator_edit)">
          <met-button [buttonText]="editCreateOperator" icon="edit" (click)="openEditOperator()"></met-button>
        </div>
      </ng-container>
      <div class="notes-container" *ngIf="hasRequiredRole(role.notice_view)">
        <div>
          <h4>Notizen</h4>
        </div>
        <div class="notes-row">
          <div *ngIf="station">
            <mat-card *ngFor="let note of station.notes | slice:0:show">
              <div [ngStyle]="{'color': note.isArchived? 'grey' : 'black'}">
                <p *ngIf="note.isArchived">Archiviert: </p>
                <div class="creationDate">
                  {{setDate(note.creationDate) }}
                  {{note.author}}
                  {{note.groupOfAuthor}}
                </div>
                <div>
                  {{note.text}}
                </div>
                <div *ngIf="!note.isArchived" class="existingRemark">
                  <img *ngIf="hasRequiredRole(role.notice_edit)"
                       (click)="openSpecificOnNoteStation(station, note)"
                       [id]="'station-note-' + note.id"
                       src="assets/icons8Note.png" alt="Paper Note"
                       style="width: 2em"
                       [matTooltip]="'Notiz bearbeiten'">
                  <img *ngIf="hasRequiredRole(role.notice_delete)"
                       (click)="archiveSpecificNoteOnStation(station , note)"
                       src="assets/icons8-trash-96.png" alt="Trash/Delete"
                       style="width: 2em; margin-left: 0.2em"
                       [matTooltip]="'Notiz löschen'">
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="notes-button">
          <ng-container *ngIf="hasRequiredRole(role.notice_edit)">
            <met-button icon="edit" (click)="openStationNote()" buttonText="Notiz hinzufügen"></met-button>
            <met-button *ngIf=" station && station.notes && show < station.notes.length" (click)="showMore()"
                        [buttonText]="'Mehr laden'"></met-button>
          </ng-container>
        </div>
      </div>
      <div *ngIf="hasRequiredRole(role.sensor_view)">
        <div>
          <h4>Sensoren</h4>
        </div>
        <div class="sensorFilter">
          <div style="align-self: center">
            Zeitraum:
          </div>
          <div class="sensorFilterItem">
            <met-button [buttonText]="'48 Stunden'" (click)="getSensorData(2)" [isRaised]="selectedTimeInterval===2">
            </met-button>
          </div>
          <div class="sensorFilterItem">
            <met-button [buttonText]="'1 Woche'" (click)="getSensorData(7)" [isRaised]="selectedTimeInterval===7">
            </met-button>
          </div>
          <div class="sensorFilterItem">
            <met-button [buttonText]="'4 Wochen'" (click)="getSensorData(28)" [isRaised]="selectedTimeInterval===28">
            </met-button>
          </div>
        </div>
        <div>
          <table mat-table aria-label=" sensors
            " [dataSource]="sensors">
            <ng-container matColumnDef="sensorId">
              <th mat-header-cell style="text-align: start" *matHeaderCellDef>SensorId</th>
              <td #td [attr.rowSpan]="calcRowSpan(sensors,sensor)" mat-cell *matCellDef="let sensor;"
                  [style.display]="sensor.sensorGroup !== '' ? '' : 'none'">
                <mat-cell [ngClass]="td.rowSpan === 2 ?
                'connected' :
                'not_connected'">
                  {{sensor.sensorId}}
                </mat-cell>
              </td>
            </ng-container>
            <ng-container matColumnDef="sensorGroup" *ngIf="span">
              <th mat-header-cell *matHeaderCellDef>SensorTyp</th>
              <td #td [attr.rowSpan]="calcRowSpan(sensors,sensor)" mat-cell *matCellDef="let sensor;"
                  [style.display]="sensor.sensorGroup !== '' ? '' : 'none'">
                <mat-cell [ngClass]="td.rowSpan === 2 ?
                'connected' :
                'not_connected'">
                  {{sensor.sensorGroup | sensorPipe}}
                </mat-cell>
              </td>
            </ng-container>
            <ng-container matColumnDef="battery">
              <th mat-header-cell *matHeaderCellDef>Batterie</th>
              <td class="sensors" mat-cell *matCellDef="let sensor">
                <mat-cell [ngClass]="sensor.batteryState">
                  {{sensor.validBatteryCapacity}}
                  / {{sensor.maximumBatteryCapacity}}
                </mat-cell>
              </td>
            </ng-container>

            <ng-container matColumnDef="sensorType">
              <th mat-header-cell *matHeaderCellDef>Parameter</th>
              <td class="sensors" mat-cell *matCellDef="let sensor">
                <mat-cell>
                  {{sensor.sensorShortCut | sensorPipe}}
                </mat-cell>
              </td>
            </ng-container>
            <ng-container matColumnDef="sensorTransmissionSignal">
              <th mat-header-cell *matHeaderCellDef>Übertragungssignal</th>
              <td mat-cell *matCellDef="let sensor" style="height: 100%">
                <mat-cell [ngClass]="sensor.transmissionSignal"></mat-cell>
              </td>
            </ng-container>
            <ng-container matColumnDef="statusComment">
              <th mat-header-cell *matHeaderCellDef>Status-Hinweis</th>
              <td class="sensors" mat-cell *matCellDef="let sensor">
                <mat-cell class="statusComment">
                  <mat-select [disabled]="!hasRequiredRole(role.status_hint_edit)"
                              [value]="sensor.sensorStatus.statusComment | getStatusCommentValue"
                              (selectionChange)="setStatusComment($event.value,sensor)">
                    <mat-option *ngFor="let comment of
                    statusComments" [value]="comment">
                      {{comment}}
                    </mat-option>
                  </mat-select>
                  <div class="statusCommentDate">
                    {{ sensor.sensorStatus.startDate | parseDate }}
                  </div>
                </mat-cell>
              </td>
            </ng-container>
            <ng-container *ngIf="hasRequiredRole(role.notice_view)" matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef>Notiz</th>
              <td class="sensors" mat-cell *matCellDef="let sensor" [id]="'sensor-note-' + sensor.sensorType">
                <mat-cell style="width: max-content;">
                  <div *ngIf="sensor.note; else emptyPageIcon" class="existingRemark">
                    <img (click)="openDialogNote(sensor, hasRequiredRole(role.notice_edit))" src="assets/icons8Note.png"
                         alt="Paper Note" style="width: 2em" [matTooltip]="sensor.note.text">
                    <ng-container *ngIf="hasRequiredRole(role.notice_delete)">
                      <img (click)="archiveRemarkNote(sensor)" src="assets/icons8-trash-96.png" alt="Trash/Delete"
                           style="width: 2em; margin-left: 0.2em" [matTooltip]="'Notiz löschen'">
                    </ng-container>
                  </div>
                  <ng-template #emptyPageIcon>
                    <ng-container *ngIf="hasRequiredRole(role.notice_edit)">
                      <img (click)="openDialogNote(sensor)" src="assets/icons8NoteEmpty.png" alt="Paper Note"
                           style="width: 2em" [matTooltip]="'Notiz erstellen'">
                    </ng-container>
                  </ng-template>
                </mat-cell>
              </td>
            </ng-container>
            <ng-container *ngIf="hasRequiredRole(role.comment_view)" matColumnDef="comment">
              <th mat-header-cell *matHeaderCellDef>Kommentar</th>
              <td class="sensors" mat-cell *matCellDef="let sensor" [id]="'sensor-comment-' + sensor.sensorType">
                <mat-cell>
                  <div *ngIf="sensor.comment; else emptyPageIcon" class="existingRemark">
                    <img (click)="openDialogComment(sensor, hasRequiredRole(role.comment_edit))"
                         src="assets/icons8Note.png" alt="Paper Comment" style="width: 2em"
                         [matTooltip]="sensor.comment.text">
                    <ng-container *ngIf="hasRequiredRole(role.comment_delete)">
                      <img (click)="archiveRemarkComment(sensor)" src="assets/icons8-trash-96.png" alt="Trash/Delete"
                           style="width: 2em; margin-left: 0.2em" [matTooltip]="'Kommentar löschen'">
                    </ng-container>
                  </div>
                  <ng-template #emptyPageIcon class="existingRemark">
                    <ng-container *ngIf="hasRequiredRole(role.comment_edit)">
                      <img (click)="openDialogComment(sensor)" src="assets/icons8NoteEmpty.png" alt="Paper Comment"
                           style="width: 2em" [matTooltip]="'Kommentar erstellen'">
                    </ng-container>
                  </ng-template>
                </mat-cell>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplaySensor"></tr>
            <tr mat-row *matRowDef="let sensors; columns: columnsToDisplaySensor"></tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="hasRequiredRole(role.image_view)">
      <h4>Bilder</h4>
      <met-images id="images" [stationId]="stationId"></met-images>
    </div>
  </div>
</met-page-layout>
