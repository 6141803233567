import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {concat, distinctUntilChanged, Observable, of, switchMap} from "rxjs";
import {KeycloakEvent, KeycloakEventType, KeycloakService} from "keycloak-angular";
import {map} from "rxjs/operators";

export interface ImageUrl {
  url: string;
  thumbnailUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private baseUrl = '/images/';

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  getStationImageUrls(stationId: string): Observable<ImageUrl[]> {
    let token = this.getAccessToken();
    return concat(of(token), this.keycloakService.keycloakEvents$.pipe(
      map((event: KeycloakEvent) => {
        const token = this.getAccessToken();
        if (event.type == KeycloakEventType.OnTokenExpired) {
          console.log("Token expired")
          this.keycloakService.updateToken(20);
        }
        if (event.type == KeycloakEventType.OnAuthRefreshSuccess) {
          console.log("Token refreshed")
        }
        return token;
      }))).pipe(distinctUntilChanged(),
      switchMap((token) => this.http.get<ImageUrl[]>(environment.API_URL + this.baseUrl + stationId)
        .pipe(map((urls: ImageUrl[]) => {
              urls.sort((a, b) => {
                return a.url.localeCompare(b.url);
              }).forEach(url => {
                if (token) {
                  url.url = url.url + "?access_token=" + token
                  url.thumbnailUrl = url.thumbnailUrl + "?access_token=" + token
                }
              })
              return urls;
            }
          )
        )));
  }

  private getAccessToken() {
    if (this.keycloakService && this.keycloakService.getKeycloakInstance()) {
      return this.keycloakService.getKeycloakInstance().token
    } else {
      return ""
    }
  }

  uploadFile(stationId: string, file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', environment.API_URL + this.baseUrl + stationId, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  deleteImage(stationId: string | null, filename: string) {
    return this.http.delete(environment.API_URL + this.baseUrl + stationId + "/" + filename)
  }
}
