import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject, switchMap, take } from 'rxjs';
import { InformationStatus, StatusType } from '../model/InformationStatus';
import { InformationStatusService } from './information-status.service';

@Injectable({
  providedIn: 'root'
})
export class InformationStatusStoreService {
  private informationStatuses = new BehaviorSubject<InformationStatus[]>([]);
  public readonly informationStatuses$ = this.informationStatuses.asObservable();
  private httpError = new Subject<any>();
  public readonly httpError$ = this.httpError.asObservable();

  constructor(private informationStatusService: InformationStatusService) { }

  load(): void {
    this.informationStatusService.get()
      .pipe(
        take(1),
        switchMap(res => res === null ? of([]) : of(res))
      )
      .subscribe({
        next: informationStatus => this.informationStatuses.next(informationStatus),
        error: httpError => this.httpError.next(httpError)
      });
  }

  update(informationStatuses: InformationStatus[]): void {
    this.informationStatusService.post(informationStatuses)
      .pipe(take(1))
      .subscribe({
        next: () => this.informationStatuses.next(informationStatuses),
        error: httpError => this.httpError.next(httpError)
      });
    ;
  }

}
