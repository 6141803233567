import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'met-image-fullscreen-viewer',
  templateUrl: './image-fullscreen-viewer.component.html',
  styleUrls: ['./image-fullscreen-viewer.component.scss']
})
export class ImageFullscreenViewerComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public imageURL: string, private dialogRef: MatDialogRef<ImageFullscreenViewerComponent >) { }

  closeViewer(): void {
    this.dialogRef.close();
  }
}
